<div class="col-md-12 col-sm-12 col-xs-12">
    <div class="container">
        <div class="col-md-12">
            <h2 class="text-dark">
                <i class="fa fa-cube"></i> Manage Category
            </h2>
            <hr>
        </div>
    </div>
</div>

<div class="container-fluid" style="background-color:white; padding: 0px;">

    <div class="card border-dark">
        <div class="card-header border-dark">
            Add New Category
        </div>
        <div class="card-body">

            <form #categoryForm="ngForm" (ngSubmit)="onSubmit(categoryForm.value)">

                <div class="row">
                    <div class="col-6">
                        <div class="picture-container">
                            <div class="picture">


                                <input #file type="file" #pic="ngModel" accept="image/*" ngModel name="pic" id="wizard-picture" (change)="preview(file.files)" required>
                                <img [src]="imgURL" height="200px" width="350px">
                            </div>
                            <pre class="">Choose Category Image </pre>
                        </div>
                    </div>
                    <div class="col-5">
                        <label for="name" class="form-label">Category Name :</label>
                        <input type="text" name="name" [class.is-invalid]="name.invalid && name.touched" maxlength="50" required #name="ngModel" ngModel class="form-control" placeholder="Category Name">
                        <pre class="text-danger" [class.d-none]="name.valid || name.untouched ">Category Name field is required </pre>
                        <br>
                        <div class="col">
                            <label >type:</label>
                            <select  (change)="selectChangeHandler($event)"  class="form-control">  
                                    <option  disabled>Choose type</option>

                                    <option  *ngFor="let order of filtertype; let i = index" [value]="filtertype[i].typeId" [selected]="filtertype">
                                        {{filtertype[i].type_name}}
                                      </option>
                                
                                </select>
                        </div>
                    </div>
                  
                </div>

                <div *ngIf='loading' style="float: right;">
                    <button type="submit" class="btn btn-outline-primary" [disabled]="!categoryForm.valid"> Add Category</button>
                </div>
                <div *ngIf='!loading' style="float: right;">
                    <button class="btn btn-primary" type="button" disabled>
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Loading...
                    </button>
                </div>
            </form>

        </div>
    </div>


    <br><br>


    <div class="card" style="box-shadow: 5px 5px 15px 1px #0005;">
        <div class="card-header">
            Category Mangement
        </div>
        <div class="card-body">

            <div *ngIf='user' class="d-flex justify-content-center">
                <div class="spinner-border" role="status">
                </div>
            </div>

            <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger " class="table table-striped table-bordered table-sm row-border hover" style="max-width: 100%; font-size:small;">
                <thead *ngIf='!user'>
                    <tr>
                        <th> ID </th>
                        <th>Name</th>
                        <th>Image</th>
                        <th>Type</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let datas of userData">
                        <td>{{ datas.id }}</td>
                        <td>{{ datas.category_name }}</td>
                        <td> <img [src]=datas.category_image alt="img" width="150px" height="150px" style="padding: 10px;"></td>
                        <td>{{ datas.type_id === 1 ? 'Fiction' : 'Non-Fiction'}}</td>

                        <td>
                            <a [routerLink]="[ '/admin/categoryedit',datas.id] "> <i class="fa fa-edit fa-lg" style="color:green; " aria-hidden="true "></i></a>
                            &nbsp;
                            <a mwlConfirmationPopover popoverTitle="Logout" popoverMessage="Want to delete category ?" placement="left" (confirm)="deletecategory(datas.id)" (cancel)="false">
                                <i class="fa fa-trash-o fa-lg " style="color:brown; " aria-hidden="true "></i></a>&nbsp;&nbsp;&nbsp;&nbsp;


                        </td>
                    </tr>
                </tbody>
            </table>

        </div>
    </div>



</div>