import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AdminService } from 'src/app/Service/admin.service';
import { Location } from '@angular/common'
import {environment } from '../../../environments/environment'
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { analyzeAndValidateNgModules } from '@angular/compiler';


@Component({
  selector: 'app-viewpost',
  templateUrl: './viewpost.component.html',
  styleUrls: ['./viewpost.component.css']
})
export class ViewpostComponent implements OnInit {

  path = environment.bucket ;
  postData: any;
  
  user = true;
  image: any;
  read = true;


 
  filtercategory: any;
  filterage: any;
  filterlang: any;
  filtertype: any;

  loading = true;
  imgURL: any = "assets/Images/uploadimage.png";
  public imagePath: any;
  public message: any;
  public img: any;
  imageSrc: string;
  book_url: any;
  fileconvert:any;
  book: any;
  load = true;
  history_data: any;
  categoryNonFiction: any;
  categoryFiction: any
  type_is:any
  // preview(files:any) {
  //   if (files.length === 0)
  //     return;
 
  //   var mimeType = files[0].type;
  //   if (mimeType.match(/image\/*/) == null) {
  //     this.message = "Only images are supported.";
  //     return;
  //   }

 
  //   var reader = new FileReader();
  //   this.imagePath = files;
  //      this.img =files[0];

    
  //   reader.readAsDataURL(files[0]); 
  //   reader.onload = (_event) => { 
  //     this.imgURL = reader.result; 
      
  //   }
  // }

  constructor(private route: ActivatedRoute,private router: Router, private adminService: AdminService,private toastr: ToastrService,private location: Location) { }


  userdetails = new FormGroup({
    name: new FormControl('', Validators.required),
    details: new FormControl('', Validators.required),
    category_id: new FormControl('', Validators.required),
    insert_date: new FormControl(),
    update_date: new FormControl(),
    age_id: new FormControl('', Validators.required),
    language_id: new FormControl('', Validators.required),
    author: new FormControl('', Validators.required),
    type_id: new FormControl('', Validators.required),
    web_book_url:new FormControl(''),
    file: new FormControl(),
    fileSource: new FormControl(),
    file1: new FormControl(),
    fileSource1: new FormControl(),
    publishers: new FormControl('', Validators.required),
    is_public: new FormControl('', Validators.required),
    is_lexile: new FormControl('', Validators.required)

  });




  ngOnInit(): void {
    const id = this.route.snapshot.paramMap.get('id');
    this.adminService.Viewpost(id).subscribe(
      (response:any)=>{
        
        this.postData = response.data['book_detail'][0];
        this.history_data = response.data['history'];
          if (response.data['history'] == null) {
            this.history_data  = false
          }

        if(response.code == 1){

          this.user = false;
          // this.postData["is_public"]=0;
          
          this.image = this.postData.image;
          this.book_url = this.postData.book_url;
          this.userdetails.controls.insert_date.disable();
          this.userdetails.controls.name;;
          this.userdetails.controls.details;
          this.userdetails.controls.category_id;
          this.userdetails.controls.age_id;
          this.userdetails.controls.type_id;
          this.userdetails.controls.language_id;
          this.userdetails.controls.author;
          this.userdetails.controls.publishers;
          this.userdetails.controls.update_date.disable();
          this.userdetails.controls.web_book_url;
          
          this.userdetails.controls.is_public;
          this.userdetails.controls.is_lexile;
          this.userdetails.patchValue(this.postData);
          

          if (response) {
            this.load = false;
          }

        }else{

        }
       
    });



    this.adminService.filterdata().subscribe(
      (response:any)=>{
        this.filtercategory = response.data['category'];
        this.filterage = response.data['age_range'];
        this.filterlang = response.data['language'];
        this.filtertype = response.data['type'];
        this.categoryNonFiction = this.filtercategory.filter(e => { 
          if (e.type_id == 2) { 
            return true
          }
        })
         this.categoryFiction = this.filtercategory.filter(e => { 
          if (e.type_id == 1) { 
            return true
          }
         })
        const temp = this.userdetails.controls['type_id'].value
        if (temp == 2) { 
          this.filtercategory=this.categoryNonFiction

        }
        if (temp == 1) { 
          this.filtercategory=this.categoryFiction

        }
        
                

    });

  }

  onFileChange(event) {
    const reader = new FileReader();
        
    if(event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);
    
      reader.onload = () => {
   
        this.imageSrc = reader.result as string;
     
        this.img =event.target.files[0];
        
        this.userdetails.patchValue({
          fileSource: reader.result
        });
   
      };
   
    }
  }
  
  onFileChanges(event) {
    const reader = new FileReader();        
    if(event.target.files && event.target.files.length) {      

      const [file] = event.target.files;
      reader.readAsDataURL(file);
    
      reader.onload = () => {
   
     //   this.imageSrc = reader.result as string;

     this.book =event.target.files[0];

     if (event.target.files[0].type == 'application/epub+zip') {
            this.fileconvert = 'epub';
        }else{
          this.fileconvert = 'pdf';
        }
        


        this.userdetails.patchValue({
          fileSource1: reader.result
        });
   
      };
   
    }
  }
  selectChangeHandler(event: any) { 
    this.type_is = event.target.value;
    if (this.type_is == 1) {
      this.filtercategory = this.categoryFiction
    }
    else { 
      this.filtercategory = this.categoryNonFiction

    }
    console.log(this.type_is)
 }
   

  onSubmit(value){
    this.loading = false;
    var id = this.route.snapshot.paramMap.get('id');
    
    value.filetype = this.fileconvert;
      
    
    this.adminService.Editbook(this.img,this.book,value,id).subscribe((response)=>{
    
      if(response['code'] == 1){
        this.loading = true;
        this.toastr.success("Book updated!");
        // this.ngOnInit();

      }else{
        this.loading = true;
        this.toastr.error("oops something went Wrong!!")

      }      

    });
   
  }

  deletepost(id){
    this.adminService.delpost(id).subscribe((response:any)=>{  
      
      if(response.code == 1){
        this.toastr.success("post Deleted!!");
        this.router.navigate(['/admin/reportedFeeds'])
      }else{
        this.toastr.error("oops something went wrong!!");
      }

    });
  }

  back(){
    this.location.back()
  }


  edit(): void {
    this.read = !this.read;

  }




}
