import {AfterViewInit, Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { AdminService } from 'src/app/Service/admin.service';
import { environment } from '../../../environments/environment'
import { FormControl, FormGroup, Validators } from '@angular/forms';


@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.css']
})
export class CategoryComponent implements OnInit ,AfterViewInit {
 
  dtOptions: DataTables.Settings = {};

  dtTrigger: Subject<any> = new Subject<any>();
  userData: any;
  user = true;
  selectedValue : any
  path = environment.category_image;
  loading = true;
  public imagePath: any;
  public message: any;
  public img: any;
  filtertype: any;
  imgURL: any = "assets/Images/uploadimage.png";
  name: any;
  type_is: any='2';
 
  preview(files:any) {
    if (files.length === 0)
      return;
 
    var mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      this.message = "Only images are supported.";
      return;
    }

 
    var reader = new FileReader();
    this.imagePath = files;
       this.img =files[0];

    
    reader.readAsDataURL(files[0]); 
    reader.onload = (_event) => { 
      this.imgURL = reader.result; 
    }
  }



  constructor(private adminService: AdminService, private toastr: ToastrService) { }
  // {pic: 'C:\\fakepath\\1648470208588cover.jpeg', name: 'Toot Fairy'}
  userdetails = new FormGroup({
    pic: new FormControl('', Validators.required),
    name: new FormControl('', Validators.required),
    type_id: new FormControl(),


  });

  ngOnInit() {
    
   
    
    

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 5,
      lengthMenu: [5,10, 15, 20, 25, 30],
      processing: true,
      responsive:true
    };

    this.adminService.Categorydata().subscribe(
      (response: any) => {
        this.userData = response.data;
        
      });
      this.adminService.filterdata().subscribe(
        (response: any) => {

          this.filtertype = response.data['type'];
          console.log(this.filtertype);
  
          
        });
  
  }

  ngAfterViewInit(): void { 
        
    setTimeout(() => {
        this.dtTrigger.next();
        this.user = false;
      }, 500);

    
    
  }
  selectChangeHandler(event: any) { 
    this.type_is = event.target.value;
    console.log(this.type_is)
 }

  onSubmit(value) {
    // {pic: 'C:\\fakepath\\1648470208588cover.jpeg', name: 'Toot Fairy'}
    value["type_id"]=this.type_is
    
    console.log(value)
    

    this.adminService.Addcategory(this.img,value).subscribe((response)=>{
    
      if(response['code'] == 1){
        this.loading = true;
        this.toastr.success("Category Added!!");
        this.ngOnInit();
        location.reload();

      }else{
        this.loading = true;
        this.toastr.error("oops something went Wrong!!")

      }      

    });
   
  }


  deletecategory(id){
    this.adminService.deletecategory(id).subscribe((response)=>{
        if(response['code'] == 1){
          this.toastr.success("Category Removed!!");
          this.ngOnInit()
        }else{
          this.toastr.error("oops something went Wrong!!");
        }
    });
  }


}
