import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AdminService } from 'src/app/Service/admin.service';

@Component({
  selector: 'app-push',
  templateUrl: './push.component.html',
  styleUrls: ['./push.component.css']
})
export class PushComponent implements OnInit {


  loading = true;
  public img: any;
  imgURL: any = "assets/Images/uploadimage.png";
  public imagePath: any;
  public message: any;
  username: any;


  preview(files:any) {
    if (files.length === 0)
      return;
 
    var mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      this.message = "Only images are supported.";
      return;
    }

 
    var reader = new FileReader();
    this.imagePath = files;
       this.img =files[0];

    
    reader.readAsDataURL(files[0]); 
    reader.onload = (_event) => { 
      this.imgURL = reader.result; 
    }
  }


  constructor(private adminService: AdminService,private toastr: ToastrService) { }

  ngOnInit(): void {

    this.adminService.username().subscribe(
      (response:any)=>{
        
        this.username = response.data;
                

    });

  }

  reset(){
      location.reload();

  }



  onSubmit(value){
    
    this.loading = false;

    this.adminService.sendpush(this.img,value).subscribe((response)=>{
    
      if(response['code'] == 1){
        this.loading = true;
        this.toastr.success("Notification sent");
        this.ngOnInit();
      //  location.reload();


      }else{
        this.loading = true;
        this.toastr.error("oops something went Wrong!!")

      }      

    });
   
  }


}
