import { Component, OnInit } from '@angular/core';
import { CKEditor4 } from 'ckeditor4-angular';
import { ToastrService } from 'ngx-toastr';
import { AdminService } from 'src/app/Service/admin.service';
@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.css']
})
export class TermsComponent implements OnInit {

  
  abc:any;
  viewdata:any;

  constructor(private adminService: AdminService,private toastr: ToastrService) { }
  ngOnInit(): void {

    this.adminService.CMSdata('terms').subscribe((response)=>{


      this.viewdata = response['data'][0].content;
      

    });



  }

  onChange( event: CKEditor4.EventInfo ) {
    this.abc = event.editor.getData();
  }

  save(){
    var data = this.abc;
    
    if(data != undefined){
      
      this.adminService.CMS('terms',data).subscribe((response:any)=>{  
      
      if(response.code ==1){
        this.toastr.success("Data Updated!!");
      }else{
        this.toastr.error("oops something went wrong!!");
      }
        });
    }

    
  }


}
