import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AdminService } from 'src/app/Service/admin.service';

@Component({
  selector: 'app-changepassword',
  templateUrl: './changepassword.component.html',
  styleUrls: ['./changepassword.component.css']
})
export class ChangepasswordComponent implements OnInit {
  

  constructor(private adminservice: AdminService,private toastr: ToastrService) { }

  ngOnInit(): void {

  }

  onSubmitReset(value){

    this.adminservice.Chnagepassword(value).subscribe((response)=>{

      if(response == 1){
        this.toastr.success("Password Chnaged Successfully","Please login again");
      }else{
        this.toastr.error(" Fails!!", "Incurrect Old Password !!")
      }

    })
  
    
  }


}
