<div class="row justify-content-sm-center">
    <div class="col-sm-6 col-md-12 ">
        <div class="card">

            <div class="row mt-3">
                <div class="col">

                    <div class="col-md-12 col-sm-12 col-xs-12">
                        <div class="container">
                            <div class="col-md-12">
                                <h2 class="text-dark">
                                    <i class="fa fa-book"></i> Add Event
                                </h2>
                                <hr>
                            </div>
                        </div>
                    </div>


                </div>
                <div class="col">
                    <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                        <a class="btn btn-outline-light md-block text-dark" (click)="back()" routerLinkActive="active "> <i class="fa fa-arrow-left fa-lg" aria-hidden="true"></i>&nbsp; Back &nbsp;&nbsp;&nbsp;</a>
                    </div>
                </div>

            </div>

            <div *ngIf="load" class="d-flex justify-content-center">
                <div class="spinner-border" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>


            <div *ngIf="!load" class="card-body">
                <div style="float: right;">
                    <button type="button" class="btn btn-outline-primary" (click)="reset()"> Reset</button>
                </div>
                <!-- 
                <form #categoryForm="ngForm" (ngSubmit)="onSubmit(categoryForm.value)">
                    <div class="row">
                        <div class="col-6">
                            <div class="picture-container">
                                <div class="picture">

                                    <input #file type="file" #pic="ngModel" accept="image/*" ngModel name="pic" id="wizard-picture" (change)="preview(file.files)">
                                    <img [src]="image" style="width: 200px; height: 200px; float: left;" alt="loading">
                                </div>
                                <pre class="">Book Poster</pre>
                            </div>
                        </div>
                    </div>

                </form> -->



                <form [formGroup]="userdetails" (ngSubmit)="onSubmit(userdetails.value)" class="form-signin">

                    <div class="form-group" style="width: 100%;">
                        <img [src]="imageSrc" *ngIf="imageSrc" style="height: 200px; width:200px" alt="">
                        <img [src]="imgURL" *ngIf="!imageSrc" style="height: 100px; width:200" alt="book poster yet to upload">
                        <!-- <br><br>
                        <a [href]="book_url" target="_blank" rel="noopener noreferrer"> <button class="btn btn-outline-primary" type="button">Epub download url</button> </a>
                        <br> -->
                    </div>

                    <hr>

                    <div class="row md-12">
                        <div class="col">
                            <label for="file">Book Image</label>
                            <input formControlName="file" type="file" accept="image/*" class="form-control" (change)="onFileChangeBook($event)">
                        </div>
                        <div class="col">
                            <label for="file">Author Image</label>
                            <input formControlName="file1" type="file" accept="image/*" class="form-control" (change)="onFileChangeImage($event)">
                        </div>
                    </div>

                    <br>

                    <hr>


                    <div class="row">
                        <div class="col">
                            <label for="name" style="float:left; "> Event Title </label>
                            <input type="text" id="name" formControlName="eventTitle" class="form-control" placeholder=" Event Title">

                        </div>

                        <div class="col">
                            <label for="author" style="float:left; "> Link of Event </label>
                            <input type="text" id="name" formControlName="eventLink" class="form-control " placeholder="Event Link">

                        </div>
                    </div>
                    <br>
                    <div class="row">
                       

                        <div class="col">
                                   
                                <label for="actualDate">Actual Date:</label>
                                <input type="datetime-local" min={{updatedMinCurrentForActual}} id="birthdaytime" formControlName="actualDate" class="form-control " (change)="actualOnChange($event)">


                        </div>
                        <div class="col">
                          
                            <label for="activeDate">Active Date:</label>
                            <input type="datetime-local" id="birthdaytime" min={{updatedMinCurrentForActive}} max={{updatedMaxCurrentForActive}} formControlName="activeDate"  class="form-control " (change)="activeOnChange($event)">


                    </div>
                    </div>

                  
                    <!-- <div class="row">
                        <div class="col">
                            <label for="publishers" style="float:left; "> Publishers </label>
                            <input type="text" id="name" formControlName="publishers" class="form-control " placeholder="Publishers">
                        </div>
                    </div>
                    <br>
                    <div class="row">
                        <div class="col">
                            <label for="details" style="float:left; "> Details </label>
                            <textarea class="form-control mb-2" id="details" formControlName="details" style="height: 100px" placeholder="Details"></textarea>


                        </div>
                    </div> -->
                    <br>
                    <!-- <div class="row">
                        <div class="col">
                            <label for="category_id">category:</label>
                            <select formControlName="category_id" class="form-control">  
                                    <option [ngValue]="null" disabled>Choose category</option>
                                    <option *ngFor="let order of filtercategory; let i = index" [value]="filtercategory[i].categoryId" [selected]="filtercategory">
                                        {{filtercategory[i].category_name}}
                                      </option>
                                
                                </select>
                        </div>
                        <div class="col">
                            <label for="type_id">type:</label>
                            <select formControlName="type_id" class="form-control">  
                                    <option [ngValue]="null" disabled>Choose type</option>

                                    <option *ngFor="let order of filtertype; let i = index" [value]="filtertype[i].typeId" [selected]="filtertype">
                                        {{filtertype[i].type_name}}
                                      </option>
                                
                                </select>
                        </div>
                        <div class="col">
                            <label for="language_id">language:</label>
                            <select formControlName="language_id" class="form-control">  
                                    <option [ngValue]="null" disabled>Choose language</option>
                                    <option *ngFor="let order of filterlang; let i = index" [value]="filterlang[i].languageId" [selected]="filterlang">
                                        {{filterlang[i].language}}
                                      </option>
                                
                                </select>
                        </div>
                        <div class="col">
                            <label for="age_id">age range:</label>
                            <select formControlName="age_id" class="form-control">  
                                    <option [ngValue]="null" disabled>Choose age range</option>
                                    <option *ngFor="let order of filterage; let i = index" [value]="filterage[i].ageId" [selected]="filterage">
                                        {{filterage[i].age_range}}
                                      </option>
                                
                                </select>
                        </div>
                    </div> -->


                    <br><br>
                    <div *ngIf='loading' class="d-grid gap-2 d-md-flex justify-content-md-center">
                        <button class="btn btn-lg  btn-outline-success btn-block mb-5"  style="width: 500px;" type="submit "> <i class="fa fa-sign-in " aria-hidden="true "></i> Publish Event </button>
                    </div>

                    <div *ngIf='!loading' class="d-grid gap-2 d-md-flex justify-content-md-center">
                        <button class="btn btn-lg  btn-outline-success btn-block mb-5" style="width: 500px;" type="button" disabled>
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Loading...
                            </button>
                    </div>


                </form>

            </div>
        </div>
    </div>
</div>