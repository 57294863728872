import { Component, OnInit } from '@angular/core';
import { AdminService } from 'src/app/Service/admin.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit  {
  active_user: any;
  total_reports: any;
  total_user: any;
  total_books: any;
  total_subscribers: any;
  total_earning: any;


  constructor(private adminService: AdminService) { }

  ngOnInit(): void {
    this.adminService.Dashboard().subscribe(
      (response: any) => {
         this.active_user = response.data[0].active_user;
        this.total_reports = response.data[0].total_reports;
        this.total_user = response.data[0].total_user;
        this.total_books = response.data[0].total_books;
        this.total_subscribers = response.data[0].total_subscribers;
        this.total_earning = response.data[0].total_earning;


      });


  }
}