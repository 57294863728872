// import { Component, OnInit } from '@angular/core';
// import { FormControl, FormGroup, Validators } from '@angular/forms';
// import { ActivatedRoute, Router } from '@angular/router';
// import { ToastrService } from 'ngx-toastr';
// import { AdminService } from 'src/app/Service/admin.service';
// import { environment } from 'src/environments/environment';
// import { Location } from '@angular/common'

import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { AdminService } from 'src/app/Service/admin.service';

@Component({
  selector: 'app-showclasses',
  templateUrl: './showclasses.component.html',
  styleUrls: ['./showclasses.component.css']
})

export class ShowClasses implements OnInit {

  dtOptions: any = {};
  dtTrigger: Subject<any> = new Subject<any>();
  feedData: any;
  feed = true;
  selectedSchool :"";
  selectSchool: any = []
  read = true;
  show = false;

  constructor(private adminService: AdminService,private route:Router,private toastr: ToastrService,@Inject(HttpClient) private httpClient: HttpClient) { }

  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      lengthMenu: [10, 15, 20, 25, 30],
      processing: true,
      responsive:true,
      dom: 'Bfltip',      
      buttons: [
        'print',
        'excel',
        
      ],
    };
    let check = true;
    
    this.adminService.getAllSchool().subscribe((response: any) => {
      console.log("665656", response.data)
      this.selectSchool = response.data
      if (this.selectSchool.length == 1) {
        this.read = false
        this.selectedSchool = this.selectSchool[0].school_code

        console.log("selectedSchool1=>",this.selectedSchool);
        this.show = true;
        this.adminService.GetAllClasses(this.selectedSchool).subscribe(
          (response: any) => {
            console.log("response=>",response);
            this.feedData = response.data;
            
          });
        
      } 
    })

    
  }
  onSelect() {
    console.log(999,this.selectedSchool)
    this.read = !this.read;
    this.read = !this.read;
    this.show = true;
    this.adminService.GetAllClasses(this.selectedSchool).subscribe(
      (response: any) => {
        console.log("response=>",response);
        this.feedData = response.data;
        
      });

  }

  ngAfterViewInit(): void { 
        
    setTimeout(() => {
        this.dtTrigger.next();
        this.feed = false;
      }, 500);

  }


  deletepost(course_id){
    
    this.adminService.delclass(course_id,this.selectedSchool).subscribe((response:any)=>{  
      console.log("111",course_id,response);
      
      if(response.code == 1){
        this.toastr.success("Deleted!!");
        this.onSelect();
      }else{
        this.toastr.error("oops something went wrong!!");
      }

    });
  }


  reload(){
    location.reload();
  }

reset() {
  this.show = false;
  }


}
