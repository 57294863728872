<div class="row justify-content-sm-center" *ngIf='!user'>
    <div class="col-sm-6 col-md-12 ">
        <div class="card">

            <div class="row mt-3">
                <div class="col">
                    <div class="col-md-12 col-sm-12 col-xs-12">
                        <div class="container">
                            <div class="col-md-12">
                                <h2 class="text-dark">
                                    <i class="fa fa-book"></i> Book Details
                                </h2>
                                <hr>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="col">

                    <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                        <a class="btn btn-outline-light md-block text-dark" (click)="back()" routerLinkActive="active "> <i class="fa fa-arrow-left fa-lg" aria-hidden="true"></i>&nbsp; Back &nbsp;&nbsp;&nbsp;</a>
                    </div>
                </div>
            </div>

            <div *ngIf="load" class="d-flex justify-content-center">
                <div class="spinner-border" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>

            <div *ngIf="!load" class="card-body">
                <div class="row">

                    <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                        <button type="button" class="btn btn-outline-primary md-block" (click)="edit()">Edit  <i class="fa fa-edit" aria-hidden="true" ></i></button>
                    </div>
                </div>

                <!-- 
                <form #categoryForm="ngForm" (ngSubmit)="onSubmit(categoryForm.value)">
                    <div class="row">
                        <div class="col-6">
                            <div class="picture-container">
                                <div class="picture">

                                    <input #file type="file" #pic="ngModel" accept="image/*" ngModel name="pic" id="wizard-picture" (change)="preview(file.files)">
                                    <img [src]="image" style="width: 200px; height: 200px; float: left;" alt="loading">
                                </div>
                                <pre class="">Book Poster</pre>
                            </div>
                        </div>
                    </div>

                </form> -->



                <form [formGroup]="userdetails" (ngSubmit)="onSubmit(userdetails.value)" class="form-signin">
                    <fieldset [disabled]='read'>

                        <div class="form-group" style="width: 50%;">
                            <img [src]="imageSrc" *ngIf="imageSrc" style="height: 200px; width:200px">
                            <img [src]="image" *ngIf="!imageSrc" style="height: 200px; width:200px">
                            <br><br>
                            <a [href]="book_url" target="_blank" rel="noopener noreferrer"> <button class="btn btn-outline-primary" type="button">Epub download url</button> </a>
                            <br>
                        </div>

                        <hr>

                        <div class="row md-12">
                            <div class="col">
                                <label for="file">Book Poster</label>
                                <input formControlName="file" type="file" accept="image/*" class="form-control" (change)="onFileChange($event)">
                            </div>
                            <div class="col">
                                <label for="file1">Book file ( Epub / Pdf )</label>
                                <!-- <input formControlName="file1" type="file" accept="application/epub+zip,application/pdf" class="form-control" (change)="onFileChanges($event)"> -->
                                <input formControlName="file1" type="file" accept="application/epub+zip" class="form-control" (change)="onFileChanges($event)">

                            </div>
                        </div>

                        <br>

                        <hr>

                        <div class="row">
                            <div class="col">
                                <label for="name" style="float:left; "> Book Name </label>
                                <input type="text" id="name" formControlName="name" class="form-control " placeholder="Parent name">
                            </div>
                            <div class="col">
                                <label for="author" style="float:left; "> Author </label>
                                <input type="text" id="name" formControlName="author" class="form-control " placeholder="Author">
                            </div>
                        </div>
                        <br>
                        <div class="row">
                            <div class="col">
                                <label for="publishers" style="float:left; "> Publishers </label>
                                <input type="text" id="name" formControlName="publishers" class="form-control " placeholder="publishers">
                            </div>
                            <div class="col">
                                <label for="publishers" style="float:left; "> Book Url </label>
                                <input type="text" id="name" formControlName="web_book_url" class="form-control " placeholder="Book Url">
                            </div>
                        </div>
                        <br>
                        <div class="row">
                            <div class="col">
                                <label for="details" style="float:left; "> Details </label>
                                <textarea class="form-control mb-2" id="details" formControlName="details" style="height: 100px" placeholder="Details"></textarea>


                            </div>
                        </div>

                        <br>

                        <div class="row">
                            <div class="col">
                                <label for="type_id">Type:</label>
                                <select (change)="selectChangeHandler($event)"  formControlName="type_id" class="form-control">  
                                    <option [ngValue]="null" disabled>Choose type</option>

                                    <option *ngFor="let order of filtertype; let i = index" [value]="filtertype[i].typeId">
                                        {{filtertype[i].type_name}}
                                      </option>
                                
                                </select>
                            </div>
                            <div class="col">
                                <label for="category_id">Category:</label>
                                <select formControlName="category_id" class="form-control">  
                                    <option [ngValue]="null" disabled>Choose category</option>
                                    <option *ngFor="let order of filtercategory; let i = index" [value]="filtercategory[i].categoryId" >
                                        {{filtercategory[i].category_name}}
                                      </option>
                                
                                </select>
                            </div>

                            <div class="col">
                                <label for="language_id">Language:</label>
                                <select formControlName="language_id" class="form-control">  
                                    <option [ngValue]="null" disabled>Choose language</option>
                                    <option *ngFor="let order of filterlang; let i = index" [value]="filterlang[i].languageId">
                                        {{filterlang[i].language}}
                                      </option>
                                
                                </select>
                            </div>
                            <div class="col">
                                <label for="age_id">Age range:</label>
                                <select formControlName="age_id" class="form-control">  
                                    <option [ngValue]="null" disabled>Choose age range</option>
                                    <option *ngFor="let order of filterage; let i = index" [value]="filterage[i].ageId">
                                        {{filterage[i].age_range}}
                                      </option>
                                
                                </select>
                            </div>
                        </div>
                        <br>


                        <div class="row md-12 d-flex align-items-center ">
                            <div class="col ">
                                <label for="insert_date" style="float:left; "> Insert Date</label>
                                <input type="text " id="insert_date " formControlName="insert_date" class="form-control mb-2 " placeholder="User Insert date">
                            </div>
                            <div class="col ">
                                <label for="update_date" style="float:left; "> Update Date</label>
                                <input type="text " id="update_date " formControlName="update_date" class="form-control mb-2 " placeholder="User Update date ">
                            </div>
                            <div class="col ">
                                <input name="is_public" ng-control="options" type="radio" [value]="1"  formControlName="is_public"  > Public   &nbsp;&nbsp;&nbsp;&nbsp;          
    
                                <input name="is_public" ng-control="options" type="radio" [value]="0" formControlName="is_public" > Private <br/>
                                
                                
        
                            </div>
                        </div>
                        <br>
                        <div class="col w-50 ">
                            <label for="name" style="float:left; "> Lexile Book  </label> &nbsp;&nbsp;&nbsp;&nbsp;          
                            <div class="col w-50  ">
                                <input name="is_lexile" ng-control="options" type="radio" [value]="1"  formControlName="is_lexile"  > Yes   &nbsp;&nbsp;&nbsp;&nbsp;          
    
                                <input name="is_lexile" ng-control="options" type="radio" [value]="0" formControlName="is_lexile" > No <br/>
                                
                                
        
                            </div>
    
                        </div>

                        <br><br>
                        <div *ngIf='loading' class="d-grid gap-2 d-md-flex justify-content-md-center">
                            <button class="btn btn-lg  btn-outline-success btn-block mb-5" style="width: 500px;" [disabled]="!userdetails.valid" type="submit "> <i class="fa fa-sign-in " aria-hidden="true "></i> Update Book</button>
                        </div>

                        <div *ngIf='!loading' class="d-grid gap-2 d-md-flex justify-content-md-center">
                            <button class="btn btn-lg  btn-outline-success btn-block mb-5" style="width: 500px;" type="button" disabled>
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Loading...
                            </button>
                        </div>


                    </fieldset>
                </form>

            </div>

            <hr>

            <div class="card text-center" style="width: 80%; align-self: center;">
                <div class="card-header">
                    User Book Reading History
                </div>
                <div class="card-body">

                    <div class="card-footer text-dark">
                        <b> {{ history_data.length || 0 }}  - User read this Book.</b>

                    </div>

                    <table class="table table-sm">
                        <thead *ngIf='history_data'>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">User Name</th>
                                <th scope="col">Chapter</th>
                                <th scope="col">Start Page</th>
                                <th scope="col">End Page</th>
                                <th scope="col">Total Page</th>
                                <th scope="col">Last interaction</th>
                            </tr>
                        </thead>
                        <tbody>

                            <tr *ngFor="let datas of history_data">
                                <th scope="row"> * </th>
                                <td (click)="navigateView()"><a> {{ datas.parent_name }} </a></td>
                                <td>{{ datas.chapter }}</td>
                                <td>{{ datas.start_page }}</td>
                                <td>{{ datas.end_page }}</td>
                                <td>{{ datas.total_page }}</td>
                                <td>{{ datas.update_date }}</td>

                            </tr>
                        </tbody>
                    </table>

                    <!-- <h5 class="card-title">Special title treatment</h5>
                  <p class="card-text">With supporting text below as a natural lead-in to additional content.</p>
                  <a href="#" class="btn btn-primary">Go somewhere</a> -->
                </div>
                <div class="card-footer text-muted">

                </div>
            </div>



            <hr>
            <br><br>







        </div>
    </div>
</div>