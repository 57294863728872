<div class="row justify-content-sm-center">
    <div class="col-sm-6 col-md-12 ">
        <div class="card">

            <div class="row mt-3">
                <div class="col">

                    <div class="col-md-12 col-sm-12 col-xs-12">
                        <div class="container">
                            <div class="col-md-12">
                                <h2 class="text-dark">
                                    <i class="fa fa-graduation-cap" aria-hidden="true"></i> Add Student
                                </h2>
                                <hr>
                            </div>
                        </div>
                    </div>


                </div>
                <div class="col">
                    <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                        <a class="btn btn-outline-light md-block text-dark" (click)="back()" routerLinkActive="active ">
                            <i class="fa fa-arrow-left fa-lg" aria-hidden="true"></i>&nbsp; Back &nbsp;&nbsp;&nbsp;</a>
                    </div>
                </div>

            </div>

            <div *ngIf="load" class="d-flex justify-content-center">
                <div class="spinner-border" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>

            <div *ngIf="!load" class="card-body">

                <div class="row">
                    <div class="col">
                    <label for="category_id">Select School:</label> 

                        <select [(ngModel)]="selectedSchool"  class="form-control" (change)="onSelect()">
                            <option [ngValue]="null" disabled>Choose School</option>
                            <option *ngFor="let option of selectSchool;  let i = index" [value]="selectSchool[i].school_code"  [selected]="selectSchool"  >
                        
                             Name : {{option.name}} , Code: {{option.school_code}}
                            </option>
                          </select>
                    </div>
                    <div class="col">
                        <div style="float: right;">
                            <button type="button" class="btn btn-outline-primary" (click)="reset()"> Reset</button>
                        </div>
                        </div>
                </div>          
            </div>

            <div *ngIf="!load" class="card-body">
               

                <form [formGroup]="userdetails" (ngSubmit)="onSubmit(userdetails.value)" class="form-signin">

                    <br><br>
                    <div class="row md-12">
                        <div class="col">
                            <label for="file">Student Excel File</label>
                            <input formControlName="file" type="file" accept="image/*" class="form-control"
                                (change)="onFileChangeBook($event)" accept=".xlsx, .xls">
                        </div>
                    </div>
                    <br><br><br>
                    <hr><br>

                    <div *ngIf='loading' class="d-grid gap-2 d-md-flex justify-content-md-center">
                        <button class="btn btn-lg  btn-outline-success btn-block mb-5" style="width: 500px;"
                            type="submit "> <i class="fa fa-sign-in " aria-hidden="true "></i> Import Student </button>
                    </div>

                    <div *ngIf='!loading' class="d-grid gap-2 d-md-flex justify-content-md-center">
                        <button class="btn btn-lg  btn-outline-success btn-block mb-5" style="width: 500px;"
                            type="button" disabled>
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            Loading...
                        </button>
                    </div>

                    <div *ngIf="show==true">

                        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" >

                            <ng-container matColumnDef="school_student_id">
                                <th mat-header-cell *matHeaderCellDef> school_student_id </th>
                                <td mat-cell *matCellDef="let element"> {{element.school_student_id}} </td>
                            </ng-container>

                            <ng-container matColumnDef="first_name">
                                <th mat-header-cell *matHeaderCellDef> first_name </th>
                                <td mat-cell *matCellDef="let element"> {{element.first_name}} </td>
                            </ng-container>

                            <ng-container matColumnDef="last_name">
                                <th mat-header-cell *matHeaderCellDef> last_name </th>
                                <td mat-cell *matCellDef="let element"> {{element.last_name}} </td>
                            </ng-container>

                            <ng-container matColumnDef="display_birth_date">
                                <th mat-header-cell *matHeaderCellDef> birth_date </th>
                                <td mat-cell *matCellDef="let element"> {{element.display_birth_date?element.display_birth_date:element.birth_date}} </td>
                            </ng-container>

                            <ng-container matColumnDef="grade">
                                <th mat-header-cell *matHeaderCellDef> grade </th>
                                <td mat-cell *matCellDef="let element"> {{element.grade}} </td>
                            </ng-container>

                            <ng-container matColumnDef="course_id">
                                <th mat-header-cell *matHeaderCellDef> course_id </th>
                                <td mat-cell *matCellDef="let element"> {{element.course_id}} </td>
                            </ng-container>

                            <ng-container matColumnDef="section">
                                <th mat-header-cell *matHeaderCellDef> section </th>
                                <td mat-cell *matCellDef="let element"> {{element.section}} </td>
                            </ng-container>

                            <ng-container matColumnDef="school_code">
                                <th mat-header-cell *matHeaderCellDef> school_code </th>
                                <td mat-cell *matCellDef="let element"> {{element.school_code}} </td>
                            </ng-container>

                            <ng-container matColumnDef="student_code">
                                <th mat-header-cell *matHeaderCellDef> student_code </th>
                                <td mat-cell *matCellDef="let element"> {{element.student_code}} </td>
                            </ng-container>
                         
                            <ng-container matColumnDef="data_status">
                                <th mat-header-cell *matHeaderCellDef>  data_status </th>
                                
                                <td mat-cell *matCellDef="let element"  [ngStyle]="element.data_status=='In-Valid' ? {'color': 'red'}:{'color': 'green'}" style="font-weight: 800;"> {{element.data_status}} </td>
                            </ng-container>
                            <ng-container matColumnDef="reason">
                                <th mat-header-cell *matHeaderCellDef>  reason </th>
                                
                                <td mat-cell *matCellDef="let element" > {{element.reason}} </td>
                            </ng-container>
                            
                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                        </table>
                    </div>

                </form>

            </div>
        </div>
    </div>
</div>
