<div class="col-md-12 col-sm-12 col-xs-12">
    <div class="container">
        <div class="col-md-12">
            <h2 class="text-dark">
                <i class="fa fa-camera"></i> Manage Reported Feeds
            </h2>
            <hr>
        </div>
    </div>
</div>
<div class="card">
    <div class="card-header">
        Reported Feeds Mangement
    </div>
    <div class="card-body">



        <div class="container-fluid" style="background-color:white; padding: 20px;">

            <div *ngIf='feed' class="d-flex justify-content-center">
                <div class="spinner-border" role="status">
                </div>
            </div>

            <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger " class="row-border hover" style="width: 80%; font-size:medium;">
                <thead *ngIf='!feed'>
                    <tr>
                        <th> Reported By</th>
                        <th> Reason</th>
                        <th> Feedback</th>
                        <th> Reported Date</th>
                        <th>Status</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let datas of FeedData">
                        <td>{{ datas.reported_by }}</td>
                        <td>{{ datas.reason }}</td>
                        <td>{{ datas.feedback }}</td>
                        <td>{{ datas.insert_date }}</td>

                        <td> <pre [ngClass]="[datas.status] ">{{ datas.status }}</pre></td>
                        <td>
                            <button type="button" class="btn" mwlConfirmationPopover popoverTitle="Delete" popoverMessage="Want to delete Report ?" placement="left" (confirm)="deletereport(datas.id)" (cancel)="false">
                                <i class="fa fa-times fa-lg " style="color:red; " aria-hidden="true "></i> </button>



                            <a [routerLink]="[ '/admin/viewpost',datas.post_id] "> <i class="fa fa-info-circle fa-lg" style="color: blue; " aria-hidden="true "></i></a>

                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

    </div>
</div>