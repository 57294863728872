import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup,FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AdminService } from 'src/app/Service/admin.service';
import {environment } from '../../../environments/environment'
import { Location } from '@angular/common'


@Component({
  selector: 'app-viewuser',
  templateUrl: './viewuser.component.html',
  styleUrls: ['./viewuser.component.css']
})
export class ViewuserComponent implements OnInit {
  userData: any;
  user = true;
  read = true;
  active = false;
  path = environment.bucket;
  image_path;
  active_status: string;
  childData: any;
  child: boolean;
  constructor(private route: ActivatedRoute,private router: Router, private adminService: AdminService,private toastr: ToastrService,private location: Location) { }

  userdetails = new FormGroup({
    id: new FormControl(),
    name: new FormControl(),
    phone: new FormControl(),
    email: new FormControl(),
    is_active: new FormControl(),
    insert_date: new FormControl(),
    update_date: new FormControl(),
    is_login: new FormControl(),
    login_type: new FormControl(),

  });
  



  ngOnInit(): void {

    const id = this.route.snapshot.paramMap.get('id');

    this.adminService.Viewuser(id).subscribe(
      (response:any)=>{
        
        this.userData = response.data.parent_details[0];
        this.childData = response.data.child_details;

        
        if (this.childData.length > 0) {
            this.child = true;
        }else{
          this.child = false;
        }
        
        if (this.userData.is_active === '1') {
          
          this.active = true;
          this.active_status = "Active"
        } else {
          this.active = false;
          this.active_status = "Inactive"

        }
        this.user = false;

        this.image_path = environment.profile_image+this.userData.profile_image;
        this.userdetails.controls.insert_date.disable();
        this.userdetails.controls.id.disable();
        this.userdetails.controls.phone.disable();
        this.userdetails.controls.email.disable();
        this.userdetails.controls.is_login.disable();
        this.userdetails.controls.login_type.disable();
        this.userdetails.controls.update_date.disable();
        this.userdetails.patchValue(this.userData);



    });
    
  }


  edit(): void {
    this.read = !this.read;

  }

  back(){
    this.location.back()
  }


  onSubmit(data){
    const id = this.route.snapshot.paramMap.get('id');
    
    this.adminService.Edituser(id,data).subscribe((response: any)=>{
      this.userData = response.data;
        this.ngOnInit();
        this.toastr.success("User Updated Successfully!!")
    });

  }


}
