<link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/css/font-awesome.min.css">
<link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />
<P class="project-title">
    <img src="../../../assets/Images/iconedsoma.png" alt="Edsoma" width="300" height="100" style="border-radius: 20px;;">
</P>
<div class="container">
    <div class="card"></div>
    <div class="card">
        <h1 class="title"> Admin Login</h1>

        <form #signinForm="ngForm" (ngSubmit)="onSubmit(signinForm.value)" class="form-signin">

            <div class="input-container">
                <input type="text" name="email" required #email="ngModel" [class.is-invalid]="email.invalid && email.touched" placeholder="Email" ngModel class="form-control mb-2">
                <pre class="text-danger" [class.d-none]="email.valid || email.untouched ">Email field is required </pre>

                <!-- <label for="username">Email</label> -->
                <div class="bar"></div>
            </div>

            <div class="input-container">
                <input type="password" name="password" required #password="ngModel" [class.is-invalid]="password.invalid && password.touched" placeholder="Password" ngModel class="form-control mb-2">
                <pre class="text-danger" [class.d-none]="password.valid || password.untouched ">Password field is required </pre>

                <!-- <label for="password">  Password</label> -->
                <div class="bar"></div>
            </div>

            <div class="button-container">
                <button class="btn btn-primary btn-block" type="submit" [disabled]="!signinForm.valid">
                    <span>Login</span>

                </button>
            </div>
        </form>



    </div>

</div>