<div class="col-md-12 col-sm-12 col-xs-12">
    <div class="container">
        <div class="col-md-12">
            <h2 class="text-dark">
                <i class="fa fa-graduation-cap" aria-hidden="true"></i> Show All Classes
            </h2>
            <hr>
        </div>
    </div>
</div>


<div class="card">
    <div class="card-header">
        Classes Mangement

        <a class="btn btn-outline-light md-block text-dark" (click)="reload()" routerLinkActive="active "> <i class="fa fa-refresh fa-lg" aria-hidden="true"></i></a>

    </div>
    <br>

    <br>
    <div class="row">
        <div class="col">
        <label for="category_id">Select School:</label> 

            <select [(ngModel)]="selectedSchool"  class="form-control" (change)="onSelect()">
                <option [ngValue]="null" disabled>Choose School</option>
                <option *ngFor="let option of selectSchool;  let i = index" [value]="selectSchool[i].school_code"  [selected]="selectSchool"  >
            
                 Name : {{option.name}} , Code: {{option.school_code}}
                </option>
              </select>
        </div>
        <div class="col">
            <div style="float: right;">
                <button type="button" class="btn btn-outline-primary" (click)="reset()"> Reset</button>
            </div>
            </div>
    </div>

   

<div class="card-body">
        <div *ngIf="show==false">
            <h2> Please Select School ... </h2>
        </div>
    <div *ngIf="show==true">
        <div class="container-fluid" style="background-color:white; padding: 20px;">

            <div *ngIf='feed' class="d-flex justify-content-center">
                <div class="spinner-border" role="status">
                </div>
            </div>


            <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-striped table-bordered table-sm row-border hover" style="width: 100%; font-size:medium;">
                <thead *ngIf='!feed'>
                    <tr>

                        <th> course_id </th>
                        <th> class_name </th>
                        <th>section</th>
                        <th>grade</th>
                        <th>start_date</th>
                        <th>end_date</th>
                        <th>school_code</th>
                        <th>class_info</th>
                        <th>action</th>

                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let datas of feedData">
                        <td>{{ datas.course_id }}</td>                        
                        <td>{{ datas.class_name }}</td>
                        <td>{{ datas.section }}</td>
                        <td>{{ datas.grade }}</td>
                        <td>{{ datas.start_date }}</td>
                        <td>{{ datas.end_date }}</td>
                        <td>{{ datas.school_code}}</td>
                        <td>{{ datas.class_info}}</td>

                        <td>
                            <a mwlConfirmationPopover popoverTitle="Logout" popoverMessage="Want to remove Class ?" placement="left" (confirm)="deletepost(datas.course_id)" (cancel)="false">
                                <i class="fa fa-trash-o fa-lg " style="color:brown; " aria-hidden="true "></i></a>&nbsp;&nbsp;&nbsp;&nbsp;

                            <a [routerLink]="[ '/admin/viewstudentdetails',datas.id] "> <i class="fa fa-edit fa-lg " style="color: green; " aria-hidden="true "></i></a>
                            <hr>
                            <a [href]="datas.book_url"> <i class="fa fa-download fa-lg " style="color:blue; " aria-hidden="true "></i></a>

                        </td>

                    </tr>
                </tbody>
            </table>
        </div>
    </div>

    
</div>
</div>