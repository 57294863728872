import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './Auth/auth.guard';
import { AboutusComponent } from './Components/aboutus/aboutus.component';
import { AddbookComponent } from './Components/addbook/addbook.component';
import { ImportSchoolComponent } from './Components/importschool/importschool.component';
import { ImportClassesComponent } from './Components/importclasses/importclasses.component';
import { ShowSchool } from './Components/showschool/showschool.component';
import { ShowClasses } from './Components/showclasses/showclasses.component';
import { ImportStudentComponent } from './Components/importstudent/importstudent.component';
import { ImportTeacherComponent } from './Components/importteacher/importteacher.component';
import { ShowStudent } from './Components/showstudent/showstudent.component';
import { ViewStudentDetails } from './Components/viewstudentdetails/viewstudentdetails.component';
import { ShowTeacher } from './Components/showteacher/showteacher.component';
import { CategoryComponent } from './Components/category/category.component';
import { CategoryeditComponent } from './Components/categoryedit/categoryedit.component';
import { ChangepasswordComponent } from './Components/changepassword/changepassword.component';
import { DashboardComponent } from './Components/dashboard/dashboard.component';
import { DefaultComponent } from './Components/default/default.component';
import { EmailComponent } from './Components/email/email.component';
import { FaqComponent } from './Components/faq/faq.component';
import { FeedsComponent } from './Components/feeds/feeds.component';
import { HomeComponent } from './Components/home/home.component';
import { LoginComponent } from './Components/login/login.component';
import { PrivacyComponent } from './Components/privacy/privacy.component';
import { PushComponent } from './Components/push/push.component';
import { RfeedsComponent } from './Components/rfeeds/rfeeds.component';
import { TermsComponent } from './Components/terms/terms.component';
import { UserComponent } from './Components/user/user.component';
import { ViewpostComponent } from './Components/viewpost/viewpost.component';
import { ViewuserComponent } from './Components/viewuser/viewuser.component';
import { EventsComponent } from './Components/events/events.component';
import { AddeventComponent } from './Components/addevent/addevent.component';
import { VieweventComponent } from './Components/viewevent/viewevent.component';
import { ViewSchoolComponent } from './Components/viewschool/viewschool.component';

const routes: Routes = [
  {
    path:'',
    component:LoginComponent
  },
  {
    path:'login',
    component:LoginComponent
  },
  {
    path:'admin',
    component:DashboardComponent,
    canActivate: [AuthGuard],
    children:[
      {
        path:'home',
        component:HomeComponent
      },
      {
        path:'user',
        component:UserComponent
      },
      {
        path:'viewuser/:id',
        component:ViewuserComponent
      },
      {
        path:'category',
        component:CategoryComponent
      },
      {
        path:'categoryedit/:id',
        component:CategoryeditComponent
      },
      {
        path:'reportedFeeds',
        component:RfeedsComponent
      }, 
      {
        path:'viewbook/:id',
        component:ViewpostComponent
      },    
      {
        path:'viewschool/:id',
        component:ViewSchoolComponent
      },          
      {
        path:'book',
        component:FeedsComponent
      },
      {
        path:'viewevent/:id',
        component:VieweventComponent
      }, 
      {
        path:'event',
        component:EventsComponent
      },   
      {
        path:'aboutus',
        component:AboutusComponent
      },
      {
        path:'faq',
        component:FaqComponent
      },
      {
        path:'privacy',
        component:PrivacyComponent
      },
      {
        path:'terms',
        component:TermsComponent
      },
      {
        path:'changepassword',
        component:ChangepasswordComponent
      },
      {
        path:'importstudent',
        component:ImportStudentComponent 
      }, 
      {
        path:'importteacher',
        component:ImportTeacherComponent 
      }, 
      {
        path:'showstudent',
        component:ShowStudent
      },
      {
        path:'showteacher',
        component:ShowTeacher
      },
      {
        path:'viewstudentdetails',
        component:ViewStudentDetails
      }, 
      {
        path:'importschool',
        component:ImportSchoolComponent
      }, 
      {
        path:'importclasses',
        component:ImportClassesComponent
      }, 
      {
        path:'showclasses',
        component:ShowClasses
      }, 
      {
        path:'showschool',
        component:ShowSchool
      }, 
      {
        path:'addbook',
        component:AddbookComponent
      }, 
      {
        path:'addevent',
        component:AddeventComponent
      },
      {
        path:'push',
        component:PushComponent
      },
      {
        path:'email',
        component:EmailComponent
      },
    ]
  },
  {
    path: '**',
    component: DefaultComponent,
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes,{ useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
