import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AdminService } from 'src/app/Service/admin.service';
import { environment } from 'src/environments/environment';
import { Location } from '@angular/common'
import { ThisReceiver } from '@angular/compiler';

@Component({
  selector: 'app-addbook',
  templateUrl: './addbook.component.html',
  styleUrls: ['./addbook.component.css']
})
export class AddbookComponent implements OnInit {

 
  path = environment.bucket;
  postData: any;

  user = true;
  image: any;
  read = true;

  load =true;

  filtercategory: any=[];
  filterage: any;
  filterlang: any;
  filtertype: any;

  loading = true;
  imgURL: any = "assets/Images/uploadimage.png";
  public imagePath: any;
  public message: any;
  public img: any;
  imageSrc: string;

  fileconvert: any;
  book: any;
  categoryNonFiction: any
  categoryFiction: any;
  type_is:any='2'



  constructor(private route: ActivatedRoute, private router: Router, private adminService: AdminService, private toastr: ToastrService, private location: Location) { }

  userdetails = new FormGroup({
    name: new FormControl('', Validators.required),
    book_url: new FormControl('', Validators.required),
    details: new FormControl('', Validators.required),
    category_id: new FormControl(32),
    age_id: new FormControl(),
    language_id: new FormControl(),
    author: new FormControl('', Validators.required),
    type_id: new FormControl(),
    file: new FormControl('', Validators.required),
    fileSource: new FormControl('', Validators.required),
    file1: new FormControl(''),
    fileSource1: new FormControl(''),
    publishers: new FormControl('', Validators.required),
    is_public:new FormControl('', Validators.required),
    is_lexile:new FormControl('', Validators.required)


  });


  ngOnInit(): void {



    this.adminService.filterdata().subscribe(
      (response: any) => {
        console.log(response)
        this.filtercategory = response.data['category'];
        this.filterage = response.data['age_range'];
        this.filterlang = response.data['language'];
        this.filtertype = response.data['type'];
        console.log(this.filtercategory)
         this.categoryNonFiction = this.filtercategory.filter(e => { 
          if (e.type_id == 2) { 
            return true
          }
        })
         this.categoryFiction = this.filtercategory.filter(e => { 
          if (e.type_id == 1) { 
            return true
          }
         })
        this.filtercategory=this.categoryNonFiction
        console.log(11,this.categoryFiction);
        console.log(22,this.categoryNonFiction)
        if (response) {
          this.load = false;
        }
      });



  }



  onFileChange(event) {
    const reader = new FileReader();

    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);

      reader.onload = () => {

        this.imageSrc = reader.result as string;

        this.img = event.target.files[0];

        this.userdetails.patchValue({
          fileSource: reader.result
        });

      };

    }
  }

  onFileChanges(event) {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {

      const [file] = event.target.files;
      reader.readAsDataURL(file);

      reader.onload = () => {

        //   this.imageSrc = reader.result as string;

        this.book = event.target.files[0];

        if (event.target.files[0].type == 'application/epub+zip') {
          this.fileconvert = 'epub';
        } else {
          this.fileconvert = 'pdf';




          // this.adminService.bookconvert(this.book).subscribe((response)=>{

          //   console.log(response);
          //   this.book = response['data']

          // if(response['code'] == 1){
       


          //  this.userdetails.patchValue({
          //   fileSource1: response
          // });

          // }else{
          //   this.toastr.error("oops something went Wrong!!")

          // }      

          // });




        }



        this.userdetails.patchValue({
          fileSource1: reader.result
        });

      };

    }
  }


  reset(){
  //  location.reload();
  this.userdetails.reset();
    this.imageSrc = null

  }
  onTypeChange(event: any) { 

  }
  selectChangeHandler(event: any) { 
    this.type_is = event.target.value;
    if (this.type_is == 1) {
      this.filtercategory = this.categoryFiction
    }
    else { 
      this.filtercategory = this.categoryNonFiction

    }
    console.log(this.type_is)
 }
  
  onSubmit(value) {

    if (this.userdetails.invalid) {
      return;
    }

    this.loading = false;

    value.filetype = this.fileconvert;

    if (value.language_id == null) {
      value.language_id = this.filterlang[0].languageId;
    }
    if (value.category_id == null) {
      value.category_id = this.filtercategory[0].categoryId;
    }
     if (value.type_id == null) {
      value.type_id = this.filtertype[0].typeId;
    } 
    if (value.age_id == null) {
      value.age_id = this.filterage[0].ageId;
    }
    
   console.log(12121,value);

    
    this.adminService.Addbook(this.img,this.book,value).subscribe((response)=>{

      if(response['code'] == 1){
        this.loading = true;
        this.toastr.success("Book Added!");
        this.ngOnInit();

      }else{
        this.loading = true;
        this.toastr.error("oops something went Wrong!!")

      }      

    });

  }


  back() {
    this.location.back()
  }


}
