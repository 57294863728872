<div class="col-md-12 col-sm-12 col-xs-12">
    <div class="container">
        <div class="col-md-12">
            <h2 class="text-dark">
                <i class="fa fa-file-text"></i> FAQ's Page
            </h2>
            <hr>
        </div>
    </div>
</div>
<br>
<br>

<div class="container-fulid " style="height: 100%; padding-right: 70px;">

    <div class="card">
        <ckeditor (change)="onChange($event)" [data]=viewdata> </ckeditor>
        <hr>
        <button class="btn btn-outline-primary" style="width: 150px; margin: 10px;" (click)="save()"> Update Info</button>


    </div>
</div>