import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { CKEditor4 } from 'ckeditor4-angular';
import { ToastrService } from 'ngx-toastr';
import { AdminService } from 'src/app/Service/admin.service';

@Component({
  selector: 'app-email',
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.css']
})
export class EmailComponent implements OnInit {

  abc:any;
  username: any;
  loading = true;


  constructor(private adminService: AdminService,private toastr: ToastrService) { }

  ngOnInit(): void {

    this.adminService.username().subscribe(
      (response:any)=>{
        
        this.username = response.data;
                

    });

  }

  onChange( event: CKEditor4.EventInfo ) {
    this.abc = event.editor.getData();
  }

  reset(){
    location.reload();
  }
  

  onSubmit(value){
    
    //this.loading = false;

    this.adminService.sendemail(value,this.abc).subscribe((response)=>{
    
      if(response['code'] == 1){
        this.loading = true;
        this.toastr.success("Email sent");
        this.ngOnInit();
       // location.reload();
      }else{
        this.loading = true;
        this.toastr.error("oops something went Wrong!!")

      }      

    });
   
  }

}
