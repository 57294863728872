<div class="col-md-12 col-sm-12 col-xs-12">
    <div class="container">
        <div class="col-md-12">
            <h2 class="text-dark">
                <i class="fa fa-tachometer"></i> Dashboard
            </h2>
            <hr>
        </div>
    </div>
</div>
<div class="container">


    <div class="row">

        <div class="col-lg-5 col-sm-10">
            <div class="card-box bg-blue">
                <div class="inner">
                    <h3> {{total_user}} </h3>
                    <p> Total User </p>
                </div>
                <div class="icon">
                    <i class="fa fa-user-plus" aria-hidden="true"></i>
                </div>
                <a routerLink='/admin/user' class="card-box-footer">View More <i class="fa fa-arrow-circle-right"></i></a>
            </div>
        </div>
        <div class="col-lg-5 col-sm-10">
            <div class="card-box bg-green">
                <div class="inner">
                    <h3> {{ active_user}} </h3>
                    <p> Total Active User (Logged In)</p>
                </div>
                <div class="icon">
                    <i class="fa fa-picture-o" aria-hidden="true"></i>
                </div>
                <a routerLink='/admin/user' class="card-box-footer">View More <i class="fa fa-arrow-circle-right"></i></a>
            </div>
        </div>
        <div class="col-lg-5 col-sm-10">
            <div class="card-box bg-orange">
                <div class="inner">
                    <h3> {{total_books }} </h3>
                    <p> Total Books in the system </p>
                </div>
                <div class="icon">
                    <i class="fa fa-book" aria-hidden="true"></i>
                </div>
                <a routerLink='/admin/book' class="card-box-footer">View More <i class="fa fa-arrow-circle-right"></i></a>
            </div>
        </div>

        <div class="col-lg-5 col-sm-10">
            <div class="card-box bg-orange">
                <div class="inner">
                    <h3> {{total_subscribers }} </h3>
                    <p> Total subscribers </p>
                </div>
                <div class="icon">
                    <i class="fa fa-user" aria-hidden="true"></i>
                </div>
                <a routerLink='' class="card-box-footer">View More <i class="fa fa-arrow-circle-right"></i></a>
            </div>
        </div>
        &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;

        <!-- <div class="col-lg-3 col-sm-6">
            <div class="card-box bg-orange">
                <div class="inner">
                    <h3> ${{total_earning }} </h3>
                    <p> Total Earnings </p>
                </div>
                <div class="icon">
                    <i class="fa fa-money" aria-hidden="true"></i>
                </div>
                <a routerLink='' class="card-box-footer">View More <i class="fa fa-arrow-circle-right"></i></a>
            </div>
        </div> -->

    </div>



</div>