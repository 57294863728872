<div class="row justify-content-sm-center" *ngIf='!user'>
    <div class="col-sm-6 col-md-12 ">
        <div class="card">

            <div class="row mt-3">
                <div class="col">
                    <div class="col-md-12 col-sm-12 col-xs-12">
                        <div class="container">
                            <div class="col-md-12">
                                <h2 class="text-dark">
                                    <i class="fa fa-book"></i> School Details
                                </h2>
                                <hr>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="col">

                    <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                        <a class="btn btn-outline-light md-block text-dark" (click)="back()" routerLinkActive="active "> <i class="fa fa-arrow-left fa-lg" aria-hidden="true"></i>&nbsp; Back &nbsp;&nbsp;&nbsp;</a>
                    </div>
                </div>
            </div>

            <div *ngIf="load" class="d-flex justify-content-center">
                <div class="spinner-border" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>

            <div *ngIf="!load" class="card-body">
                <div class="row">

                    <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                        <button type="button" class="btn btn-outline-primary md-block" (click)="edit()">Edit  <i class="fa fa-edit" aria-hidden="true" ></i></button>
                    </div>
                </div>

                
                <form #categoryForm="ngForm" (ngSubmit)="onSubmit(categoryForm.value)">
                    <div class="row">
                        <div class="col-6">
                            <div class="picture-container">
                                <div class="picture">

                                    <input #file type="file" #pic="ngModel" accept="image/*" ngModel name="pic" id="wizard-picture" (change)="preview(file.files)" (change)="onFileChange($event)">
                                     <div *ngIf='!check' >
                                        <img [src]="image" *ngIf="image" style="width: 200px; height: 200px; float: left;" alt="loading">
                                               </div>
                                    <div *ngIf='check' >
                                    <img [src]="imgURL" *ngIf="image" style="width: 200px; height: 200px; float: left;" alt="loading">
                                     </div> 
                                  
                                    
                                </div>
                                <pre class="">School Image</pre>
                            </div>
                        </div>
                    </div>

                </form>



                <form [formGroup]="userdetails" (ngSubmit)="onSubmit(userdetails.value)" class="form-signin">
                    <fieldset [disabled]='read'>

                        <div class="form-group" style="width: 50%;">
                           
                            <!-- <img [src]="imgURL" *ngIf="!imgURL" style="height: 200px; width:200px"> -->
                            <!-- <br><br>
                            <a [href]="book_url" target="_blank" rel="noopener noreferrer"> <button class="btn btn-outline-primary" type="button">Epub download url</button> </a>
                            <br> -->
                        </div>

                        <hr>

        

                        <br>

                        <hr>

                        <div class="row">
                            <div class="col">
                                <label for="name" style="float:left; "> School Name </label>
                                <input type="text" id="name" formControlName="name" class="form-control " placeholder="School Name">
                            </div>
                            <div class="col">
                                <label for="author" style="float:left; "> School Code </label>
                                <input type="text" id="name" formControlName="school_code" class="form-control " placeholder="School Code">
                            </div>
                        </div>
                        <br>
                        <div class="row">
                            <div class="col">
                                <label for="publishers" style="float:left; "> Low Grade </label>
                                <input type="text" id="name" formControlName="low_grade" class="form-control " placeholder="Low Grade">
                            </div>
                            <div class="col">
                                <label for="publishers" style="float:left; "> High Grade </label>
                                <input type="text" id="name" formControlName="high_grade" class="form-control " placeholder="High Grade">
                            </div>
                        </div>
                        <br>
                        <div class="row">
                            <div class="col">
                                <label for="details" style="float:left; "> Phone </label>
                                <input class="form-control mb-2" id="details" formControlName="phone"  placeholder="Phone">


                            </div>
                            <div class="col">
                                <label for="details" style="float:left; "> Principal Name </label>
                                <input class="form-control mb-2" id="details" formControlName="principal_name"  placeholder="Principal Name">


                            </div>
                            <div class="col">
                                <label for="details" style="float:left; "> Admin Email </label>
                                <input class="form-control mb-2" id="details" formControlName="admin_email" placeholder="Admin Email">


                            </div>
                        </div>

                        <br>
                        <div class="row">
                            <div class="col">
                                <label for="details" style="float:left; "> Address </label>
                                <input class="form-control mb-2" id="name" formControlName="address"  placeholder="Address">


                            </div>
                            <div class="col">
                                <label for="details" style="float:left; "> City </label>
                                <input class="form-control mb-2" id="details" formControlName="city"  placeholder="City">


                            </div>
                            <div class="col">
                                <label for="details" style="float:left; "> State </label>
                                <input class="form-control mb-2" id="details" formControlName="state"  placeholder="State">


                            </div>
                            <div class="col">
                                <label for="details" style="float:left; "> Zip </label>
                                <input class="form-control mb-2" id="details" formControlName="zip"  placeholder="Zip">


                            </div>
                            <div class="col">
                                <label for="details" style="float:left; "> Districts </label>
                                <input class="form-control mb-2" id="details" formControlName="districts"  placeholder="Districts">


                            </div>
                        </div>

                    


                        <div class="row md-12 d-flex align-items-center ">
                            <div class="col ">
                                <label for="insert_date" style="float:left; "> Insert Date</label>
                                <input type="text " id="insert_date " formControlName="insert_date" class="form-control mb-2 " placeholder="User Insert date">
                            </div>
                            <div class="col ">
                                <label for="update_date" style="float:left; "> Update Date</label>
                                <input type="text " id="update_date " formControlName="update_date" class="form-control mb-2 " placeholder="User Update date ">
                            </div>
                            <!-- <div class="col ">
                                <input name="is_public" ng-control="options" type="radio" [value]="1"  formControlName="is_public"  > Public   &nbsp;&nbsp;&nbsp;&nbsp;          
    
                                <input name="is_public" ng-control="options" type="radio" [value]="0" formControlName="is_public" > Private <br/>
                                
                                
        
                            </div> -->
                        </div>
                       

                        <br><br>
                        <div *ngIf='loading' class="d-grid gap-2 d-md-flex justify-content-md-center">
                            <button class="btn btn-lg  btn-outline-success btn-block mb-5" style="width: 500px;" [disabled]="!userdetails.valid" type="submit "> <i class="fa fa-sign-in " aria-hidden="true "></i> Update Book</button>
                        </div>

                        <!-- <div *ngIf='!loading' class="d-grid gap-2 d-md-flex justify-content-md-center">
                            <button class="btn btn-lg  btn-outline-success btn-block mb-5" style="width: 500px;" type="button" disabled>
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Loading...
                            </button>
                        </div> -->


                    </fieldset>
                </form>

            </div>

          






        </div>
    </div>
</div>