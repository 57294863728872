<div class="col-md-12 col-sm-12 col-xs-12">
    <div class="container">
        <div class="col-md-12">
            <h2 class="text-dark">
                <i class="fa fa-users"></i> Manage User
            </h2>
            <hr>
        </div>
    </div>
</div>


<div class="card">
    <div class="card-header">
        User Mangement

        <a class="btn btn-outline-light md-block text-dark" (click)="reload()" routerLinkActive="active "> <i class="fa fa-refresh fa-lg" aria-hidden="true"></i></a>
    </div>
    <div class="card-body">



        <div class="container-fluid" style="background-color:white; padding: 20px;">

            <div *ngIf='user' class="d-flex justify-content-center">
                <div class="spinner-border" role="status">
                </div>
            </div>


            <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger " class="table table-bordered table-bordered table-sm row-border hover" cellspacing="0" style="width: 100%; font-size:medium;">
                <thead *ngIf='!user'>
                    <tr>
                        <th> ID </th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>phone</th>
                        <!-- <th>Status</th> -->
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let datas of userData">
                        <td>{{ datas.id }}</td>
                        <td>{{ datas.name }}</td>
                        <td>{{ datas.email }}</td>
                        <td>{{ datas.phone }}</td>


                        <!-- <td> <pre [ngClass]="[datas.is_active] ">{{ datas.is_active }}</pre></td> -->
                        <td>

                            <a mwlConfirmationPopover popoverTitle="Delete" popoverMessage="Want to delete user ?" placement="right" (confirm)="deleteuser(datas.id)" (cancel)="false">
                                <i class="fa fa-trash-o fa-lg " style="color:brown; " aria-hidden="true "></i></a>&nbsp;&nbsp;&nbsp;&nbsp;

                            <a [routerLink]="[ '/admin/viewuser',datas.id] "> <i class="fa fa-info-circle fa-lg" style="color: blue; " aria-hidden="true "></i></a>

                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

    </div>
</div>