<div class="row justify-content-sm-center" *ngIf='!user'>
    <div class="col-sm-6 col-md-12 ">
        <div class="card">

            <div class="row mt-3">
                <div class="col">
                    <div class="col-md-12 col-sm-12 col-xs-12">
                        <div class="container">
                            <div class="col-md-12">
                                <h2 class="text-dark">
                                    <i class="fa fa-book"></i> Event Details
                                </h2>
                                <hr>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="col">

                    <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                        <a class="btn btn-outline-light md-block text-dark" (click)="back()" routerLinkActive="active "> <i class="fa fa-arrow-left fa-lg" aria-hidden="true"></i>&nbsp; Back &nbsp;&nbsp;&nbsp;</a>
                    </div>
                </div>
            </div>

            <div *ngIf="load" class="d-flex justify-content-center">
                <div class="spinner-border" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>

            <div *ngIf="!load" class="card-body">
                <div class="row">

                    <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                        <button type="button" class="btn btn-outline-primary md-block" (click)="edit()">Edit  <i class="fa fa-edit" aria-hidden="true" ></i></button>
                    </div>
                </div>

                <!-- 
                <form #categoryForm="ngForm" (ngSubmit)="onSubmit(categoryForm.value)">
                    <div class="row">
                        <div class="col-6">
                            <div class="picture-container">
                                <div class="picture">

                                    <input #file type="file" #pic="ngModel" accept="image/*" ngModel name="pic" id="wizard-picture" (change)="preview(file.files)">
                                    <img [src]="image" style="width: 200px; height: 200px; float: left;" alt="loading">
                                </div>
                                <pre class="">Book Poster</pre>
                            </div>
                        </div>
                    </div>

                </form> -->



                <form [formGroup]="eventDetails" (ngSubmit)="onSubmit(eventDetails.value)" class="form-signin">
                    <fieldset [disabled]='read'>

                        <div class="row">

                            <div class="col">
                                <div class="form-group" style="width: 50%;">
                                    <img [src]="imageSrcforBook" *ngIf="imageSrcforBook" style="height: 200px; width:200px">
                                    <img [src]="image" *ngIf="!imageSrcforBook" style="height: 200px; width:200px">
                                   
                                </div>
    

                            </div>
                            <div class="col">
                                <div class="form-group" style="width: 50%;">
                                    <img [src]="imageSrcforAuthor" *ngIf="imageSrcforAuthor" style="height: 200px; width:200px">
                                    <img [src]="book_url" *ngIf="!imageSrcforAuthor" style="height: 200px; width:200px">
                                   
                                </div>
    

                            </div>

                            
                        </div>

                        


                        <hr>

                        <div class="row md-12">
                            <div class="col">
                                <label for="file">Book Poster</label>
                                <input formControlName="file" type="file" accept="image/*" class="form-control"  (change)="onFileChange($event)">
                            </div>
                            <div class="col">
                                <label for="file">Author Poster</label>
                                <input formControlName="file" type="file" accept="image/*" class="form-control" (change)="onFileChanges($event)">
                            </div>
                        </div>

                        <br>

                        <hr>

                        <div class="row">
                            <div class="col">
                                <label for="name" style="float:left; "> Event Title </label>
                                <input type="text" id="eventTitle" formControlName="eventTitle" class="form-control " placeholder="Event Title">
                            </div>
                            <div class="col">
                                <label for="author" style="float:left; "> Event Link </label>
                                <input type="text" id="eventLink" formControlName="eventLink" class="form-control " placeholder="Event Link">
                            </div>
                        </div>
                        
                        <br>
                

                        <br>

                        <div class="row">
                       

                            <div class="col">
                                       
                                    <label for="actualDate">Actual Date:</label>
                                    <input type="datetime-local" min={{updatedMinCurrentForActual}} id="birthdaytime" formControlName="actualDate" class="form-control " (change)="actualOnChange($event)">
    
    
                            </div>
                            <div class="col">
                              
                                <label for="activeDate">Active Date:</label>
                                <input type="datetime-local" id="birthdaytime" min={{updatedMinCurrentForActive}} max={{updatedMaxCurrentForActive}} formControlName="activeDate"  class="form-control " (change)="activeOnChange($event)">
    
    
                        </div>
                        </div>
                        <br>   <br>


                        <div class="row ">
                            <div class="col ">
                                <label for="insert_date" style="float:left; "> Insert Date</label>
                                <input type="text " id="insert_date " formControlName="createdDate" class="form-control mb-2 " placeholder="User Insert date">
                            </div>
                            <div class="col ">
                                <label for="update_date" style="float:left; "> Update Date</label>
                                <input type="text " id="update_date " formControlName="updatedDate" class="form-control mb-2 " placeholder="User Update date ">
                            </div>
                        </div>

                        <br>
                        <br>
                        <div *ngIf='loading' class="d-grid gap-2 d-md-flex justify-content-md-center">
                            <button class="btn btn-lg  btn-outline-success btn-block mb-5" style="width: 500px;" [disabled]="!eventDetails.valid" type="submit "> <i class="fa fa-sign-in " aria-hidden="true "></i> Update Book</button>
                        </div>

                        <div *ngIf='!loading' class="d-grid gap-2 d-md-flex justify-content-md-center">
                            <button class="btn btn-lg  btn-outline-success btn-block mb-5" style="width: 500px;" type="button" disabled>
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Loading...
                            </button>
                        </div>


                    </fieldset>
                </form>

            </div>

            <hr>

  



            <hr>
            <br><br>







        </div>
    </div>
</div>