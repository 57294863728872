import { ThisReceiver } from '@angular/compiler';
import { HttpClient } from '@angular/common/http';
import { format } from 'date-fns';



import { Subject } from 'rxjs';
import { Component, OnInit } from '@angular/core';
  import { FormControl, FormGroup, Validators } from '@angular/forms';
  import { ActivatedRoute, Router } from '@angular/router';
  import { ToastrService } from 'ngx-toastr';
  import { AdminService } from 'src/app/Service/admin.service';
  import { environment } from 'src/environments/environment';
  import { Location } from '@angular/common'
  import { DatePipe } from '@angular/common'
  import * as XLSX from 'xlsx';
import { MatNoDataRow, MatTableDataSource } from '@angular/material/table';
import { saveAs } from 'file-saver';
// import { log } from 'console';

@Component({
  selector: 'app-importstudent',
  templateUrl: './importstudent.component.html',
  styleUrls: ['./importstudent.component.css']
})
  
export class ImportStudentComponent implements OnInit {
  minCurrent: any;
  actualDate: any;
  activeDate: any;
  public file:any;

  path = environment.bucket;
  postData: any;

  user = true;
  image: any;
  read = true;

  load =false;

  filtercategory: any;
  filterage: any;
  filterlang: any;
  filtertype: any;

  loading = true;
  imgURL: any = "assets/Images/Event.png";
  public imagePath: any;
  public message: any;
  public img: any;
  imageSrc: string;
  book_url: any;
  fileconvert: any;
  book: any;
  updatedMinCurrentForActual: any;
  updatedMaxCurrentForActual: any;
  updatedMinCurrentForActive: any;
  updatedMaxCurrentForActive: any;
  excelData: unknown[];
  file_name:any;
  show=false;
  filename:any;
  feedData: any=[];
  afterApi:false
  feed = false;
  dtOptions: any = {};
  dtTrigger: Subject<any> = new Subject<any>();
  displayedColumns: string[] = ['school_student_id','first_name','last_name','display_birth_date',	'grade',	'course_id',	'section'	, 'school_code'	, 'student_code' ,'data_status','reason'];
  dataSource: any;
  convertedArray: any = []
  selectSchool: any = []
  selectedSchool = ""

  constructor(private datePipe : DatePipe,private route: ActivatedRoute, private router: Router, private adminService: AdminService, private toastr: ToastrService, private location: Location) { }

  userdetails = new FormGroup({
  file: new FormControl('', Validators.required),

  });


  ngOnInit(): void {

    this.adminService.getAllSchool().subscribe((response: any) => {
      console.log("665656", response.data)
      this.selectSchool = response.data
      if (this.selectSchool.length>= 1) {
        this.read = false
        this.selectedSchool=this.selectSchool[0].school_code
        

        console.log("selectedSchool",this.selectedSchool);
        
      }
    })


  }
  actualOnChange(event: any) { 
    console.log("actualOnChange");
    this.actualDate = event.target.value
    
    this.updatedMaxCurrentForActive=this.actualDate   
    if (this.activeDate =='') { 
      this.activeDate=undefined

    }
    else if (this.activeDate == undefined) { 
      this.updatedMaxCurrentForActive=this.actualDate   
    }
    else if (this.activeDate > this.actualDate) { 
      this.toastr.error("Actual Date & Time  should be greater then Active Time")
    }

    this.actualDate=event.target.value
console.log(event.target.value)
  }
  activeOnChange(event: any) { 
    console.log(5556, event.target.value)
    console.log("activeOnChange");
    this.activeDate = event.target.value
   
    this.updatedMinCurrentForActual =  this.activeDate
    console.log(this.updatedMinCurrentForActual, this.activeDate);
    if (this.activeDate == '') { 
      this.activeDate=undefined

    }
    else if (this.actualDate == undefined) { 
      this.updatedMinCurrentForActual=this.activeDate
    }
    else if (this.updatedMinCurrentForActual > this.activeDate) { 
      this.toastr.error("Time should be correct")
    }
    

    else if (this.actualDate < this.activeDate) { 
      console.log("3543")
      this.toastr.error("Active Date can't be greater than Actual Date!!")
    }

  }
  onFileChangeBook(event) {
  
    
    const reader = new FileReader();
  //  let file_name;
    if (event.target.files && event.target.files.length) {
      
      const [file] = event.target.files;
     this.filename = event.target.files[0];
      console.log("file=>",file);
      reader.readAsDataURL(file);
      console.log("111hello");

      reader.onload = () => {

      
        this.file = event.target.files[0];
        console.log("event.target.files[0]",event.target.files[0].name);

        this.userdetails.patchValue({
          fileSource: reader.result
        });
      };
    

      
    }

  const file = event.target.files[0];
  
 
  this.file_name =  event.target.files[0];
  // let fileExtension=this.file_name.split('.').pop(); 
  console.log(this.file_name.name.split('.')[this.file_name.name.split('.').length-1],3333)
  let file_extension=this.file_name.name.split('.')[this.file_name.name.split('.').length-1]
    if (file) {
      const fileReader = new FileReader();
      
      fileReader.onload = (e) => {
        const arrayBuffer: any = fileReader.result;
        const data = new Uint8Array(arrayBuffer);
        const workbook = XLSX.read(data, { type: 'array' });
        const firstSheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[firstSheetName];
        this.excelData = XLSX.utils.sheet_to_json(worksheet,{rawNumbers:false});
         console.log(worksheet,55)
         console.log(this.excelData,66)
        
        
        this.convertedArray = this.excelData.map((item: any) => {
          let x=new Date(item.birth_date) ;
          
          console.log("x",x);
          
          return {
            school_student_id: item.school_student_id,
            first_name: item.first_name,
            last_name:item.last_name,
            display_birth_date:format(x, 'MM/dd/yy'),
            birth_date: format(x, 'yyyy-MM-dd'),
            grade: item.grade,
            course_id: item.course_id,
            section: item.section,
            email: item.email,
            school_code: item.school_code,
            student_code: item.student_code,
            data_status: item.school_code==this.selectedSchool?"Valid":"In-Valid",
            reason:item.school_code==this.selectedSchool?"":"Different School Code",
          }
        })
        
        console.log(this.convertedArray,11)
        this.dataSource = new MatTableDataSource(this.convertedArray)
        this.dataSource = this.dataSource.filteredData
        console.log(this.dataSource,"----------------")
      };
      fileReader.readAsArrayBuffer(file);
      this.show=true;
    }
    
  }
    formatDate(dateNumeric: number): string {
    const date = new Date(0);
    date.setUTCDate(dateNumeric);
    return date.toISOString().split('T')[0];
  }

  getHeaders(row: any){
    console.log(row);
    // return Object.keys(row);
  }
  onFileChangeImage(event) {
    const reader = new FileReader();
    console.log("onFileChangeImage");
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);

      reader.onload = () => {

        

        this.book = event.target.files[0];
        this.userdetails.patchValue({
                  fileSource1: reader.result
                });

      };

    }
  }
  
  reset(){

    this.userdetails.reset();
    this.imageSrc = null
    this.show = false
    this.dataSource=null;

}
  
  onSubmit(value) {
    let check = true;
    if (!this.userdetails.valid) {
      this.toastr.error("All fields are required")
    }
    else {
      let m=this.dataSource.map(e => {
        if (e.school_code != this.selectedSchool) {

          check = false
        }
        return e
      })
      if (!check) {
        this.toastr.error("Students have different Schools")
      } else {
      this.adminService.ImportStudent(m).subscribe((response)=>{

        console.log("response",response);

        this.convertedArray = response["data"].map((item: any) => {
          let x=new Date(item.birth_date) ;
       
 
          return {
            school_student_id: item.school_student_id,
            first_name: item.first_name,
            last_name:item.last_name,
            
            birth_date:   format(x, 'MM/dd/yy'),
            grade: item.grade,
            course_id: item.course_id,
            section: item.section,
            email: item.email,
            school_code: item.school_code,
            student_code: item.student_code,
            data_status:item.status,
            reason:item.reason,
        }
      })

    
      this.dataSource = new MatTableDataSource(this.convertedArray)
      this.dataSource = this.dataSource.filteredData
      console.log(this.dataSource,"calling api")
          const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.convertedArray);
          const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
          const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      
          const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          saveAs(blob, 'student_file.xlsx');
        


        if(response['code'] == 1){
          this.loading = true;
          this.toastr.success("Student Added!");
          this.ngOnInit();
          this.userdetails.reset();  
        }else{
          this.loading = true;
          this.toastr.error("oops something went Wrong!!")
  
        }      
  
     });
    }
  }
  }
  onSelect(value) {
    console.log(999,this.selectedSchool)
    this.read = false;
    this.reset()
  }
  
  back() {
    this.location.back()
  }

  }
  
  
  
 
  

