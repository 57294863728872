import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class AdminService {

  constructor(private http: HttpClient) { }
  body = ['key', 'val'];


  Login(body: any) {

    return this.http.post(environment.APIURL + '/login', body,
      {
        headers: {
          'api-key': 'vtG7ivS6VdvBjabhz+ooyw==',
        }
      },
    );
  }


  Chnagepassword(value) {

    return this.http.post(environment.APIURL + '/chnagepassword/', value,
      {
        headers: {
          'api-key': 'vtG7ivS6VdvBjabhz+ooyw=='
        }
      },
    );
  }

  Userdata() {

    return this.http.get(environment.APIURL + '/userdata',
      {
        headers: {
          'api-key': 'vtG7ivS6VdvBjabhz+ooyw=='
        }
      },
    );
  }

  Viewuser(id) {

    return this.http.get(environment.APIURL + '/userdetail/' + id,
      {
        headers: {
          'api-key': 'vtG7ivS6VdvBjabhz+ooyw=='
        }
      },
    );
  }

  Edituser(id, data) {

    return this.http.post(environment.APIURL + '/edituser/' + id, data,
      {
        headers: {
          'api-key': 'vtG7ivS6VdvBjabhz+ooyw=='
        }
      },
    );

  }

  deluser(id) {

    return this.http.get(environment.APIURL + '/deleteuser/' + id,
      {
        headers: {
          'api-key': 'vtG7ivS6VdvBjabhz+ooyw=='
        }
      },
    );

  }


  Categorydata() {

    return this.http.get(environment.APIURL + '/categorydata',
      {
        headers: {
          'api-key': 'vtG7ivS6VdvBjabhz+ooyw=='
        }
      },
    );
  }


  Categoryview(id) {

    return this.http.get(environment.APIURL + '/categoryview/' + id,
      {
        headers: {
          'api-key': 'vtG7ivS6VdvBjabhz+ooyw=='
        }
      },
    );
  }

  Addcategory(body: File, value: any) {

    const formData = new FormData();
    formData.append('img', body);
    formData.append('name', value.name);
    formData.append('type_id', value.type_id);

   // console.log(formData);

    return this.http.post(environment.APIURL + '/addcategory', formData,
      {
        headers: {
          'api-key': 'vtG7ivS6VdvBjabhz+ooyw=='
        }
      },
    );
  }

  sendpush(body: File, value: any) {

    // var a:Array = value.id;    

    const formData = new FormData();
    formData.append('img', body);
    formData.append('name', value.name);
    formData.append('id', value.id);

  //  console.log(formData);

    return this.http.post(environment.APIURL + '/sendpush', formData,
      {
        headers: {
          'api-key': 'vtG7ivS6VdvBjabhz+ooyw=='
        }
      },
    );
  }


  sendemail(id, text) {
    
    var data = {
      "id": id.id,
      "text":text
    }
    return this.http.post(environment.APIURL + '/sendemail/', data,
      {
        headers: {
          'api-key': 'vtG7ivS6VdvBjabhz+ooyw=='
        }
      },
    );
  }


  Editcategory(body: File, value: any, id) {
    var formDatas;
    if (body == undefined) {
      formDatas = {
        "name": value.name,
        "id": id,
        "img": "",
        "type_id":value.type_id
      }
    } else {
      formDatas = new FormData();
      formDatas.append('img', body);
      formDatas.append('id', id);
      formDatas.append('name', value.name);
      formDatas.append('type_id', value.type_id);
    }
    return this.http.post(environment.APIURL + '/editcategory', formDatas,
      {
        headers: {
          'api-key': 'vtG7ivS6VdvBjabhz+ooyw=='
        }
      },
    );
  }


  Editbook(body: File, book: File, value: any, id: any) {

    
    
    var formData1;


    if (value.fileSource == null && value.fileSource1 == null) {
      formData1 = value;

    } else {
      formData1 = new FormData();
      formData1.append('name', value.name);
      formData1.append('details', value.details);
      formData1.append('category_id', value.category_id);
      formData1.append('age_id', value.age_id);
      formData1.append('language_id', value.language_id);
      formData1.append('author', value.author);
      formData1.append('type_id', value.type_id);
      formData1.append('publishers', value.publishers);
      formData1.append('web_book_url', value.web_book_url);
      formData1.append('is_public', value.is_public);
      formData1.append('is_lexile', value.is_lexile);
    
      if (body) {
        formData1.append('img', body);
      }
 
      if (book) {
        formData1.append('bimg', book);
      }

    }
    return this.http.post(environment.APIURL + '/editbook/' + id, formData1,
      {
        headers: {
          'api-key': 'vtG7ivS6VdvBjabhz+ooyw=='
        }
      },
    );
  }
  Editschool(body: File, value: any, id: any) {

    
    
    var formData1;


    if (value.fileSource == null && value.fileSource1 == null) {
      formData1 = value;

    } else {
      formData1 = new FormData();
      formData1.append('name', value.name);
      formData1.append('low_grade', value.low_grade);
      formData1.append('high_grade', value.high_grade);
      formData1.append('school_code', value.school_code);
      formData1.append('phone', value.phone);
      formData1.append('principal_name', value.principal_name);
      formData1.append('admin_email', value.admin_email);
      formData1.append('address', value.address);
      formData1.append('city', value.city);
      formData1.append('state', value.state);
      formData1.append('zip', value.zip);
      formData1.append('districts', value.districts);

    
    
      if (body) {
        formData1.append('img', body);
      }  

    }
    return this.http.post(environment.APIURL + '/editschool/' + id, formData1,
      {
        headers: {
          'api-key': 'vtG7ivS6VdvBjabhz+ooyw=='
        }
      },
    );
  }
  Editevent(body: File, book: File, value: any, id: any) {

    // console.log(value);
     
    var formData1;
    // eventTitle: new FormControl('', Validators.required),
    // eventLink: new FormControl('', Validators.required),
    // authorImg: new FormControl('', Validators.required),
    // bookImg: new FormControl(),
    // activeDate: new FormControl(),
    // actualDate: new FormControl('', Validators.required),
    // createdDate: new FormControl('', Validators.required),
    // updatedDate: new FormControl('', Validators.required),
    
    // file: new FormControl(),
    // fileSource: new FormControl(),
    // file1: new FormControl(),
    // fileSource1: new FormControl(),
 
 
     if (value.fileSource == null && value.fileSource1 == null) {
       formData1 = value;
 
     } else {
       formData1 = new FormData();
       formData1.append('eventTitle', value.eventTitle);
       formData1.append('eventLink', value.eventLink);
       
       formData1.append('activeDate', value.activeDate);
       formData1.append('actualDate', value.actualDate);

     
       if (body) {
         formData1.append('bimg', body);
       }
  
       if (book) {
         formData1.append('img', book);
       }
 
     }
    console.log(formData1)
     return this.http.post(environment.APIURL + '/editevent/' + id, formData1,
       {
         headers: {
           'api-key': 'vtG7ivS6VdvBjabhz+ooyw=='
         }
       },
     );
   }


  Addbook(body: File, book: File, value: any) {

    var formData1;

    if (value.fileSource == null && value.fileSource1 == null) {
      formData1 = value;

    } else {

      formData1 = new FormData();
      formData1.append('name', value.name);
      formData1.append('details', value.details);
      formData1.append('category_id', 12);
      formData1.append('age_id', value.age_id);
      formData1.append('language_id', 4);
      formData1.append('author', value.author);
      formData1.append('type_id', 1);
      formData1.append('publishers', value.publishers);
      formData1.append('web_book_url', value.book_url);
      formData1.append('is_public', value.is_public);
      formData1.append('is_lexile', value.is_lexile);

      if (body) {
        formData1.append('img', body);
      }
 
      if (book) {
        formData1.append('bimg', book);
      }

    }
    
    return this.http.post(environment.APIURL + '/addbook', formData1,
      {
        headers: {
          'api-key': 'vtG7ivS6VdvBjabhz+ooyw==',
        }
      },
    );
  }

  AddEvent  (body: File, book: File, value: any) {

    var formData1;

    if (value.fileSource == null && value.fileSource1 == null) {
      formData1 = value;

    } else {


      formData1 = new FormData();
      formData1.append('eventTitle', value.eventTitle);
      
      formData1.append('eventLink', value.eventLink);
      formData1.append('activeDate', value.activeDate);
      formData1.append('actualDate', value.actualDate);


      if (body) {
        formData1.append('img', body);
      }
 
      if (book) {
        formData1.append('bimg', book);
      }

    }
    
    return this.http.post(environment.APIURL + '/addEvent', formData1,
      {
        headers: {
          'api-key': 'vtG7ivS6VdvBjabhz+ooyw==',
        }
      },
    );
  }
  
  ImportStudent  (value: any) {
    return this.http.post(environment.APIURL + '/importstudent', value,
      {
        headers: {
          'api-key': 'vtG7ivS6VdvBjabhz+ooyw==',
        }
      },
    );
  }
  getAllSchool() {
    return this.http.get(environment.APIURL + '/getAllSchool',
      {
        headers: {
          'api-key': 'vtG7ivS6VdvBjabhz+ooyw==',
        }
      },
    );
  }

  ImportSchool  (value: any) {
    return this.http.post(environment.APIURL + '/importschool', value,
      {
        headers: {
          'api-key': 'vtG7ivS6VdvBjabhz+ooyw==',
        }
      },
    );
  }

  ImportClasses  (value: any) {
    return this.http.post(environment.APIURL + '/importclasses', value,
      {
        headers: {
          'api-key': 'vtG7ivS6VdvBjabhz+ooyw==',
        }
      },
    );
  }


  ImportTeacher  (value: any) {
    return this.http.post(environment.APIURL + '/importTeacher', value,
      {
        headers: {
          'api-key': 'vtG7ivS6VdvBjabhz+ooyw==',
        }
      },
    );
  }


  bookconvert(book: File) {
    
   

   var formData;    
      formData = new FormData();
       // formData.append('book', book);
        if (book) {          
          formData.append('bimg', book);
        }


 return this.http.post(environment.APIURL + '/convertbook', formData,
   {
     headers: {
       'api-key': 'vtG7ivS6VdvBjabhz+ooyw==',
     }
   },
 );



  }



  deletecategory(id) {
    return this.http.delete(environment.APIURL + '/deletecategory/' + id,
      {
        headers: {
          'api-key': 'vtG7ivS6VdvBjabhz+ooyw=='
        }
      },
    );
  }


  Reportedfeeds() {

    return this.http.get(environment.APIURL + '/reportfeeds',
      {
        headers: {
          'api-key': 'vtG7ivS6VdvBjabhz+ooyw=='
        }
      },
    );
  }

  deletereport(id) {
    return this.http.delete(environment.APIURL + '/deletereport/' + id,
      {
        headers: {
          'api-key': 'vtG7ivS6VdvBjabhz+ooyw=='
        }
      },
    );
  }

  Viewpost(id) {

    return this.http.get(environment.APIURL + '/bookdetail/' + id,
      {
        headers: {
          'api-key': 'vtG7ivS6VdvBjabhz+ooyw=='
        }
      },
    );
  }
  Viewschool(id) {

    return this.http.get(environment.APIURL + '/viewschool/' + id,
      {
        headers: {
          'api-key': 'vtG7ivS6VdvBjabhz+ooyw=='
        }
      },
    );
  }
  Viewevent(id) {

    return this.http.get(environment.APIURL + '/eventdetail/' + id,
      {
        headers: {
          'api-key': 'vtG7ivS6VdvBjabhz+ooyw=='
        }
      },
    );
  }
  filterdata() {

    return this.http.get(environment.APIURL + '/filterlist',
      {
        headers: {
          'api-key': 'vtG7ivS6VdvBjabhz+ooyw=='
        }
      },
    );
  }



  username() {

    return this.http.get(environment.APIURL + '/username',
      {
        headers: {
          'api-key': 'vtG7ivS6VdvBjabhz+ooyw=='
        }
      },
    );
  }


  delpost(id) {

    return this.http.get(environment.APIURL + '/deletebook/' + id,
      {
        headers: {
          'api-key': 'vtG7ivS6VdvBjabhz+ooyw=='
        }
      },
    );

  }
  delschool(id) {
    return this.http.post(environment.APIURL + '/deleteschool/' + id,
      {
        headers: {
          'api-key': 'vtG7ivS6VdvBjabhz+ooyw=='
        }
      },
    );

  }

  delclass(course_id,school_code) {
    var data = {
      "course_id":course_id,
      "school_code":school_code
    }
    return this.http.post(environment.APIURL + '/deleteclass' , data,
      {
        headers: {
          'api-key': 'vtG7ivS6VdvBjabhz+ooyw=='
        }
      },
    );

  }

  delstudent(id) {

    return this.http.post(environment.APIURL + '/deletestudent/' + id,
      {
        headers: {
          'api-key': 'vtG7ivS6VdvBjabhz+ooyw=='
        }
      },
    );

  }

  delteacher(id) {

    return this.http.post(environment.APIURL + '/deleteteacher/' + id,
      {
        headers: {
          'api-key': 'vtG7ivS6VdvBjabhz+ooyw=='
        }
      },
    );

  }

  delevent(id) {

    return this.http.get(environment.APIURL + '/deleteevent/' + id,
      {
        headers: {
          'api-key': 'vtG7ivS6VdvBjabhz+ooyw=='
        }
      },
    );

  }

  Postdata() {

    return this.http.get(environment.APIURL + '/bookdata',
      {
        headers: {
          'api-key': 'vtG7ivS6VdvBjabhz+ooyw=='
        }
      },
    );
  }
  
  GetAllStudent(value) {

    return this.http.post(environment.APIURL + '/showAllStudent/' + value,
      {
        headers: {
          'api-key': 'vtG7ivS6VdvBjabhz+ooyw=='
        }
      },
    );
  }

  GetAllClasses(value) {
  console.log("api ");

    return this.http.post(environment.APIURL + '/getClasses/' + value,
      {
        headers: {
          'api-key': 'vtG7ivS6VdvBjabhz+ooyw=='
        }
      },
    );
  }

  GetAllTeacher(value) {

    return this.http.post(environment.APIURL + '/showTeacher/'+value,
      {
        headers: {
          'api-key': 'vtG7ivS6VdvBjabhz+ooyw=='
        }
      },
    );
  }

  EventData() {

    return this.http.get(environment.APIURL + '/eventData',
      {
        headers: {
          'api-key': 'vtG7ivS6VdvBjabhz+ooyw=='
        }
      },
    );
  }

  Dashboard() {

    return this.http.get(environment.APIURL + '/dashboard',
      {
        headers: {
          'api-key': 'vtG7ivS6VdvBjabhz+ooyw=='
        }
      },
    );
  }

  CMSdata(title) {

    return this.http.get(environment.APIURL + '/cmsdata/' + title,
      {
        headers: {
          'api-key': 'vtG7ivS6VdvBjabhz+ooyw=='
        }
      },
    );
  }

  CMS(title, data) {
    data = {
      "content": data
    }
    return this.http.post(environment.APIURL + '/cms/' + title, data,
      {
        headers: {
          'api-key': 'vtG7ivS6VdvBjabhz+ooyw=='
        }
      },
    );
  }

}
