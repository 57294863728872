<div class="row justify-content-sm-center">
    <div class="col-sm-6 col-md-12 ">
        <div class="card">

            <div class="row mt-3">
                <div class="col">

                    <div class="col-md-12 col-sm-12 col-xs-12">
                        <div class="container">
                            <div class="col-md-12">
                                <h2 class="text-dark">
                                    <i class="fa fa-graduation-cap" aria-hidden="true"></i> Add School
                                </h2>
                                <hr>
                            </div>
                        </div>
                    </div>


                </div>
                <div class="col">
                    <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                        <a class="btn btn-outline-light md-block text-dark" (click)="back()" routerLinkActive="active ">
                            <i class="fa fa-arrow-left fa-lg" aria-hidden="true"></i>&nbsp; Back &nbsp;&nbsp;&nbsp;</a>
                    </div>
                </div>

            </div>

            <div *ngIf="load" class="d-flex justify-content-center">
                <div class="spinner-border" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>


            <div *ngIf="!load" class="card-body">
                <div style="float: right;">
                    <button type="button" class="btn btn-outline-primary" (click)="reset()"> Reset</button>
                </div>

                <form [formGroup]="userdetails" (ngSubmit)="onSubmit(userdetails.value)" class="form-signin">

                    <br><br>
                    <div class="row md-12">
                        <div class="col">
                            <label for="file">School File</label>
                            <input formControlName="file" type="file" accept="image/*" class="form-control"
                                (change)="onFileChangeBook($event)" accept=".xlsx, .xls">
                        </div>
                    </div>
                    <br><br><br>
                    <hr><br>

                    <div *ngIf='loading' class="d-grid gap-2 d-md-flex justify-content-md-center">
                        <button class="btn btn-lg  btn-outline-success btn-block mb-5" style="width: 500px;"
                            type="submit "> <i class="fa fa-sign-in " aria-hidden="true "></i> Import School </button>
                    </div>

                    <div *ngIf='!loading' class="d-grid gap-2 d-md-flex justify-content-md-center">
                        <button class="btn btn-lg  btn-outline-success btn-block mb-5" style="width: 500px;"
                            type="button" disabled>
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            Loading...
                        </button>
                    </div>

                    <div *ngIf="show==true">

                        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
                            
                            <ng-container matColumnDef="name">
                                <th mat-header-cell *matHeaderCellDef> name </th>
                                <td mat-cell *matCellDef="let element"> {{element.name}} </td>
                            </ng-container>

                            <ng-container matColumnDef="low_grade">
                                <th mat-header-cell *matHeaderCellDef> low_grade </th>
                                <td mat-cell *matCellDef="let element"> {{element.low_grade}} </td>
                            </ng-container>

                            <ng-container matColumnDef="high_grade">
                                <th mat-header-cell *matHeaderCellDef> high_grade </th>
                                <td mat-cell *matCellDef="let element"> {{element.high_grade}} </td>
                            </ng-container>

                            <ng-container matColumnDef="phone">
                                <th mat-header-cell *matHeaderCellDef> phone </th>
                                <td mat-cell *matCellDef="let element"> {{element.phone}} </td>
                            </ng-container>

                            <ng-container matColumnDef="zip">
                                <th mat-header-cell *matHeaderCellDef> zip </th>
                                <td mat-cell *matCellDef="let element"> {{element.zip}} </td>
                            </ng-container>

                            <ng-container matColumnDef="address">
                                <th mat-header-cell *matHeaderCellDef> address </th>
                                <td mat-cell *matCellDef="let element"> {{element.address}} </td>
                            </ng-container>

                            <ng-container matColumnDef="city">
                                <th mat-header-cell *matHeaderCellDef> city </th>
                                <td mat-cell *matCellDef="let element"> {{element.city}} </td>
                            </ng-container>

                            <ng-container matColumnDef="state">
                                <th mat-header-cell *matHeaderCellDef> state </th>
                                <td mat-cell *matCellDef="let element"> {{element.state}} </td>
                            </ng-container>

                            <ng-container matColumnDef="admin_email">
                                <th mat-header-cell *matHeaderCellDef> admin_email </th>
                                <td mat-cell *matCellDef="let element"> {{element.admin_email}} </td>
                            </ng-container>

                            <ng-container matColumnDef="principal_name">
                                <th mat-header-cell *matHeaderCellDef> principal_name </th>
                                <td mat-cell *matCellDef="let element"> {{element.principal_name}} </td>
                            </ng-container>

                            <ng-container matColumnDef="districts">
                                <th mat-header-cell *matHeaderCellDef> districts </th>
                                <td mat-cell *matCellDef="let element"> {{element.districts}} </td>
                            </ng-container>

                            <ng-container matColumnDef="school_code">
                                <th mat-header-cell *matHeaderCellDef> school_code </th>
                                <td mat-cell *matCellDef="let element"> {{element.school_code}} </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                        </table>
                    </div>

                </form>

            </div>
        </div>
    </div>
</div>