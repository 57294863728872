import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AdminService } from 'src/app/Service/admin.service';
import { environment } from 'src/environments/environment';
import { Location } from '@angular/common'
import { DatePipe } from '@angular/common'

@Component({
  selector: 'app-addevent',
  templateUrl: './addevent.component.html',
  styleUrls: ['./addevent.component.css']
})
export class AddeventComponent implements OnInit {
  minCurrent: any;
  actualDate: any;
  activeDate: any;


  path = environment.bucket;
  postData: any;

  user = true;
  image: any;
  read = true;

  load =false;

  filtercategory: any;
  filterage: any;
  filterlang: any;
  filtertype: any;

  loading = true;
  imgURL: any = "assets/Images/Event.png";
  public imagePath: any;
  public message: any;
  public img: any;
  imageSrc: string;
  book_url: any;
  fileconvert: any;
  book: any;
  updatedMinCurrentForActual: any;
  updatedMaxCurrentForActual: any;
  updatedMinCurrentForActive: any;
  updatedMaxCurrentForActive: any;



  constructor(private datePipe : DatePipe,private route: ActivatedRoute, private router: Router, private adminService: AdminService, private toastr: ToastrService, private location: Location) { }

  userdetails = new FormGroup({
    eventTitle: new FormControl('', Validators.required),
    
    eventLink: new FormControl('', Validators.required),
      activeDate: new FormControl('', Validators.required),
      actualDate: new FormControl('', Validators.required),
   
    file: new FormControl('', Validators.required),
    fileSource: new FormControl('', Validators.required),
    file1: new FormControl('', Validators.required),
    fileSource1: new FormControl('', Validators.required),
    


  });


  ngOnInit(): void {

    var date1 = new Date()
    // var now_utc = Date.UTC(date1.getUTCFullYear(), date1.getUTCMonth(),
    // date1.getUTCDate(), date1.getUTCHours(),
    // date1.getUTCMinutes(), date1.getUTCSeconds());

 
    // console.log(55,this.datePipe.transform(date1.toUTCString(), 'YYYY-MM-ddThh:mm'));
    // console.log(new Date(date1).toUTCString());



    

    const date = new Date();
    this.updatedMinCurrentForActual = this.datePipe.transform(date, 'YYYY-MM-ddThh:mm');
    this.updatedMinCurrentForActive = this.datePipe.transform(date, 'YYYY-MM-ddThh:mm');
    this.updatedMaxCurrentForActive=undefined
    // console.log(this.updatedMinCurrentForActual);
    


  }
  actualOnChange(event: any) { 

    this.actualDate = event.target.value
    
    this.updatedMaxCurrentForActive=this.actualDate   
    if (this.activeDate =='') { 
      this.activeDate=undefined

    }
    else if (this.activeDate == undefined) { 
      this.updatedMaxCurrentForActive=this.actualDate   
    }
    else if (this.activeDate > this.actualDate) { 
      this.toastr.error("Actual Date & Time  should be greater then Active Time")
    }

    this.actualDate=event.target.value
console.log(event.target.value)
  }
  activeOnChange(event: any) { 
    console.log(5556, event.target.value)
   
    this.activeDate = event.target.value
   
    this.updatedMinCurrentForActual =  this.activeDate
    console.log(this.updatedMinCurrentForActual, this.activeDate);
    if (this.activeDate == '') { 
      this.activeDate=undefined

    }
    else if (this.actualDate == undefined) { 
      this.updatedMinCurrentForActual=this.activeDate
    }
    else if (this.updatedMinCurrentForActual > this.activeDate) { 
      this.toastr.error("Time should be correct")
    }
    

    else if (this.actualDate < this.activeDate) { 
      console.log("3543")
      this.toastr.error("Active Date can't be greater than Actual Date!!")
    }

  }



  onFileChangeBook(event) {
    const reader = new FileReader();

    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);

      reader.onload = () => {

        this.imageSrc = reader.result as string;

        this.img = event.target.files[0];

        this.userdetails.patchValue({
          fileSource: reader.result
        });

      };

    }
  }
  onFileChangeImage(event) {
    const reader = new FileReader();

    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);

      reader.onload = () => {

        

        this.book = event.target.files[0];
        this.userdetails.patchValue({
                  fileSource1: reader.result
                });

      };

    }
  }

  // onFileChanges(event) {
  //   const reader = new FileReader();
  //   if (event.target.files && event.target.files.length) {

  //     const [file] = event.target.files;
  //     reader.readAsDataURL(file);

  //     reader.onload = () => {

  //       //   this.imageSrc = reader.result as string;

  //       this.book = event.target.files[0];

  //       if (event.target.files[0].type == 'application/epub+zip') {
  //         this.fileconvert = 'epub';
  //       } else {
  //         this.fileconvert = 'pdf';




  //         // this.adminService.bookconvert(this.book).subscribe((response)=>{

  //         //   console.log(response);
  //         //   this.book = response['data']

  //         // if(response['code'] == 1){
       


  //         //  this.userdetails.patchValue({
  //         //   fileSource1: response
  //         // });

  //         // }else{
  //         //   this.toastr.error("oops something went Wrong!!")

  //         // }      

  //         // });




  //       }



  //       this.userdetails.patchValue({
  //         fileSource1: reader.result
  //       });

  //     };

  //   }
  // }


  reset(){
  //  location.reload();
  this.userdetails.reset();
    this.imageSrc = null
    this.updatedMaxCurrentForActive = undefined
    
    this.updatedMaxCurrentForActual=undefined
    
    this.updatedMaxCurrentForActive = undefined
    const date = new Date();
    this.updatedMinCurrentForActual = this.datePipe.transform(date, 'YYYY-MM-ddThh:mm');
    this.updatedMinCurrentForActive = this.datePipe.transform(date, 'YYYY-MM-ddThh:mm');
    this.actualDate = undefined;
    this.activeDate=undefined

}
  
  onSubmit(value) {

    
    console.log(999,value)
    if (!this.userdetails.valid) {
      this.toastr.error("All fields are required")
    }
    else if (this.activeDate > this.actualDate) { 
      this.toastr.error("Actual Date & Time should be greater then Active Time")
    }
    else if (this.updatedMinCurrentForActual > this.activeDate) { 
      this.toastr.error("Time should be correct")
    }
    

    else if (this.actualDate < this.activeDate) { 
      console.log("3543")
      this.toastr.error("Active Date can't be greater than Actual Date!!")
    }
    else { 

      let activeDateCheck = new Date(value.activeDate);
      activeDateCheck.setHours(activeDateCheck.getHours() - 5);
      activeDateCheck.setMinutes(activeDateCheck.getMinutes() - 30);

      let actualDateCheck = new Date(value.actualDate);
      actualDateCheck.setHours(actualDateCheck.getHours() - 5);
      actualDateCheck.setMinutes(actualDateCheck.getMinutes() - 30);

      console.log(556, this.datePipe.transform(activeDateCheck.toUTCString(), 'YYYY-MM-ddThh:mm'))
      console.log(556, this.datePipe.transform(actualDateCheck.toUTCString(), 'YYYY-MM-ddThh:mm'))
      value.activeDate = this.datePipe.transform(activeDateCheck.toUTCString(), 'YYYY-MM-ddThh:mm')
      value.actualDate=this.datePipe.transform(actualDateCheck.toUTCString(), 'YYYY-MM-ddThh:mm')

      this.loading = false;
  
      value.filetype = 'png';


      
    //  console.log(this.book);
  
      
      this.adminService.AddEvent(this.img,this.book,value).subscribe((response)=>{
  
        if(response['code'] == 1){
          this.loading = true;
          this.toastr.success("Event Added!");
          this.ngOnInit();
          this.userdetails.reset();
          this.imageSrc = null
          this.updatedMaxCurrentForActive = undefined
          
          this.updatedMaxCurrentForActual=undefined
          
          this.updatedMaxCurrentForActive = undefined
          const date = new Date();
          this.updatedMinCurrentForActual = this.datePipe.transform(date, 'YYYY-MM-ddThh:mm');
          this.updatedMinCurrentForActive = this.datePipe.transform(date, 'YYYY-MM-ddThh:mm');
          this.actualDate = undefined;
          this.activeDate=undefined
  
        }else{
          this.loading = true;
          this.toastr.error("oops something went Wrong!!")
  
        }      
  
      });
    }


 

  }


  back() {
    this.location.back()
  }


}
