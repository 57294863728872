<div class="row justify-content-sm-center mt-2" *ngIf='!user'>
    <div class="col-sm-6 col-md-12 ">
        <div class="card">

            <div class="row mt-3">
                <div class="col">
                    <div class="col-md-12 col-sm-12 col-xs-12">
                        <div class="container">
                            <div class="col-md-12">
                                <h2 class="text-dark">
                                    <i class="fa fa-user"></i> User Details
                                </h2>
                                <hr>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="col">

                    <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                        <a class="btn btn-outline-light md-block text-dark" (click)="back()" routerLinkActive="active "> <i class="fa fa-arrow-left fa-lg" aria-hidden="true"></i>&nbsp; Back &nbsp;&nbsp;&nbsp;</a>
                    </div>
                </div>
            </div>

            <div class="card-body">
                <div class="row">

                    <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                        <button type="button" class="btn btn-outline-primary md-block" (click)="edit()">Edit  <i class="fa fa-edit" aria-hidden="true" ></i></button>
                    </div>
                </div>
                <form [formGroup]="userdetails" (ngSubmit)="onSubmit(userdetails.value)" class="form-signin">
                    <fieldset [disabled]='read'>

                        <div class="row">
                            <div class="col">
                                <label for="id" style="float:left;"> Profile Picture</label>
                                <img [src]=image_path style="width: 200px; height: 200px; border-radius: 200px; float: left;" alt="">

                            </div>

                        </div>
                        <hr>


                        <div class="d-grid gap-2 d-md-flex justify-content-md-end">


                            <select class="form-select" style="width: 200px;" formControlName="is_active">
                                <option  value="1"> Active </option>
                                <option value="0" >Inactive</option>
                              </select>

                        </div>


                        <div class="row">
                            <div class="col">
                                <label for="id" style="float:left;"> Parent id</label>
                                <input type="text" id="id" formControlName="id" class="form-control mb-2" placeholder="ID">
                            </div>
                            <div class="col">
                                <label for="name" style="float:left; "> Parent Name </label>
                                <input type="text" id="name" formControlName="name" class="form-control " placeholder="Parent name">
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <label for="phone" style="float:left;"> Phone</label>
                                <input type="text" id="phone" formControlName="phone" class="form-control mb-2" placeholder="Phone">
                            </div>
                            <div class="col">
                                <label for="email" style="float:left; "> Email </label>
                                <input type="text" id="email " formControlName="email" class="form-control " placeholder="Email">
                            </div>
                        </div>

                        <div class="row">
                            <div class="col">
                                <label for="is_login" style="float:left;"> LoggedIn</label>
                                <input type="text" id="is_login" formControlName="is_login" class="form-control mb-2" placeholder="loggedIn">
                            </div>
                            <div class="col">
                                <label for="login_type" style="float:left; "> Login type </label>
                                <input type="login_type" id="login_type" formControlName="login_type" class="form-control mb-2 " placeholder="Login type">
                            </div>
                        </div>


                        <div class="row ">
                            <div class="col ">
                                <label for="insert_date" style="float:left; "> Insert Date</label>
                                <input type="text " id="insert_date " formControlName="insert_date" class="form-control mb-2 " placeholder="User Insert date">
                            </div>
                            <div class="col ">
                                <label for="update_date" style="float:left; "> Update Date</label>
                                <input type="text " id="update_date " formControlName="update_date" class="form-control mb-2 " placeholder="User Update date ">
                            </div>
                        </div>


                        <hr>
                        <div *ngIf="!child">
                            <p>Children not added yet....</p>
                        </div>

                        <div class="container mt-5">
                            <div class="row">
                                <div class="col-md-4" *ngFor="let hero of this.childData; let i = index">
                                    <div class="card p-3">
                                        <div class="d-flex flex-row mb-3"><img [src]="this.childData[i].image" width="70">
                                            <div class="d-flex flex-column ml-2"><span>{{this.childData[i].name}}</span><span class="text-black-50">{{this.childData[i].birth_date}}</span></div>
                                        </div>
                                        <h6>Code - <b>{{ this.childData[i].child_code }}</b></h6>
                                        <h6>Date of created : {{ this.childData[i].insert_date }}</h6>

                                        <!-- <div class="d-flex justify-content-between install mt-3"><span>Installed 172 times</span><span class="text-primary">View&nbsp;<i class="fa fa-angle-right"></i></span></div> -->
                                    </div>
                                </div>


                            </div>
                        </div>


                        <br>
                        <div class="d-grid gap-2 d-md-flex justify-content-md-center">
                            <button class="btn btn-lg  btn-outline-success btn-block mb-5" type="submit "> <i class="fa fa-sign-in " aria-hidden="true "></i> Update user</button>
                        </div>



                    </fieldset>
                </form>

            </div>
        </div>
    </div>
</div>