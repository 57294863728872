import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AdminService } from 'src/app/Service/admin.service';
import { environment } from 'src/environments/environment';
import { Location } from '@angular/common'
import { FormControl, FormGroup, Validators } from '@angular/forms';


@Component({
  selector: 'app-categoryedit',
  templateUrl: './categoryedit.component.html',
  styleUrls: ['./categoryedit.component.css']
})
export class CategoryeditComponent implements OnInit {
  loading = true;
  imgURL: any = "assets/Images/uploadimage.png";
  public imagePath: any;
  public message: any;
  public img: any;
  userData: any;
  names:any;
  path = environment.category_image;
  filtertype: any
  type_is: any;
  selectedType: any;
  defaultValue: any;



  preview(files:any) {
    if (files.length === 0)
      return;
 
    var mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      this.message = "Only images are supported.";
      return;
    }

 
    var reader = new FileReader();
    this.imagePath = files;
       this.img =files[0];

    
    reader.readAsDataURL(files[0]); 
    reader.onload = (_event) => { 
      this.imgURL = reader.result; 
      
    }
  }

  constructor(private adminService: AdminService, private toastr: ToastrService, private route: ActivatedRoute, private router: Router, private location: Location) { }
  userdetails = new FormGroup({

    type_id: new FormControl('', Validators.required),


  });

  ngOnInit(): void {
    var id = this.route.snapshot.paramMap.get('id');

    this.adminService.Categoryview(id).subscribe(
      (response: any) => {
        
        this.userData = response.data[0];
        this.imgURL = this.userData.category_image;
        this.names = this.userData.category_name;
        this.selectedType = this.userData.type_id
        console.log("*/*/*",this.userData);
        
        this.userdetails.patchValue(this.userData)
        
      });
      this.adminService.filterdata().subscribe(
        (response: any) => {

          this.filtertype = response.data['type'];
          console.log(this.filtertype);
  
          
        });
    console.log(555,this.userdetails.controls)


  }



  onSubmit(value){
    this.loading = false;
    var id = this.route.snapshot.paramMap.get('id');
    const temp = this.userdetails.controls['type_id'].value
    console.log(temp)
    value["type_id"]=temp

    this.adminService.Editcategory(this.img,value,id).subscribe((response)=>{
    
      if(response['code'] == 1){
        this.loading = true;
        this.toastr.success("Category Edited!!");


      }else{
        this.loading = true;
        this.toastr.error("oops something went Wrong!!")

      }      

    });
   
  }


  back(){
    this.location.back()
  }
}
