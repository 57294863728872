<div class="col-md-12 col-sm-12 col-xs-12">
    <div class="container">
        <div class="col-md-12">
            <h2 class="text-dark">
                <i class="fa fa-cube"></i> Email notification
            </h2>
            <hr>
        </div>
    </div>
</div>


<div class="card border-dark">
    <div class="card-header border-dark">
        send email notification
        <div style="float: right;">
            <button type="button" class="btn btn-outline-primary" (click)="reset()"> Reset</button>
        </div>
    </div>
    <div class="card-body">

        <form #categoryForm="ngForm" (ngSubmit)="onSubmit(categoryForm.value)">


            <ckeditor (change)="onChange($event)"> </ckeditor>

            <br><br>

            <div class="row">
                <div class="col">
                    <label for="category_id">User :</label>
                    <select class="form-control" [class.is-invalid]="id.invalid && id.touched" name="id" required #id="ngModel" ngModel [ngModelOptions] multiple style="height:200px; width: 50%;">  
                                <!-- <option [ngValue]="null" disabled>select user</option> -->
                                <option  *ngFor="let order of username; let i = index" [value]="username[i].id" >
                                    {{username[i].name}}
                                  </option>
                            </select>
                    <pre class="text-danger" [class.d-none]="id.valid || id.untouched ">User is required </pre>

                    <pre>Press Ctrl for select multiple / Ctrl+A for select all</pre>

                </div>
            </div>

            <br><br>
            <div *ngIf='loading' style="float: right;">
                <button type="submit" class="btn btn-outline-primary" [disabled]="!categoryForm.valid"> Send Notification</button>
            </div>
            <div *ngIf='!loading' style="float: right;">
                <button class="btn btn-primary" type="button" disabled>
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Loading...
                </button>
            </div>
        </form>

    </div>
</div>