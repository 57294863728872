import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AdminService } from 'src/app/Service/admin.service';
import { Location } from '@angular/common'
import {environment } from '../../../environments/environment'
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { analyzeAndValidateNgModules } from '@angular/compiler';


@Component({
  selector: 'app-viewschool',
  templateUrl: './viewschool.component.html',
  styleUrls: ['./viewschool.component.css']
})
export class ViewSchoolComponent implements OnInit {

  path = environment.bucket ;
  postData: any;
  
  user = true;
  image: any;
  read = true;
   check:boolean=false;


 
  filtercategory: any;
  filterage: any;
  filterlang: any;
  filtertype: any;

  loading = true;
  imgURL: any = "";
  public imagePath: any;
  public message: any;
  public img: any;
  imageSrc: string;
  book_url: any;
  fileconvert:any;
  book: any;
  load = true;
  history_data: any;
  categoryNonFiction: any;
  categoryFiction: any
  type_is:any
  preview(files:any) {
    if (files.length === 0)
      return;

      this.check=true
 
    var mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      this.message = "Only images are supported.";
      return;
    }

 
    var reader = new FileReader();
    this.imagePath = files;
       this.img =files[0];

    
    reader.readAsDataURL(files[0]); 
    reader.onload = (_event) => { 
      this.imgURL = reader.result; 
      
    }
  }

  constructor(private route: ActivatedRoute,private router: Router, private adminService: AdminService,private toastr: ToastrService,private location: Location) { }


  userdetails = new FormGroup({
    name: new FormControl('', Validators.required),
    low_grade: new FormControl('', Validators.required),
    high_grade: new FormControl('', Validators.required),
    school_code: new FormControl(),
    phone: new FormControl(),
    principal_name: new FormControl('', Validators.required),
    admin_email: new FormControl('', Validators.required),
    address: new FormControl('', Validators.required),
    city: new FormControl('', Validators.required),
    state:new FormControl(''),
    zip:new FormControl(''),
    districts:new FormControl(''),
    file: new FormControl(),
    fileSource: new FormControl(),
    file1: new FormControl(),
    fileSource1: new FormControl(),
    insert_date:new FormControl(''),
    update_date:new FormControl(''),

  });





  ngOnInit(): void {
    const id = this.route.snapshot.paramMap.get('id');
    this.adminService.Viewschool(id).subscribe(
      (response:any)=>{
        
        this.postData = response.data['school_detail'][0];

        if(response.code == 1){

          this.user = false;
          // this.postData["is_public"]=0;
          
          this.image = this.postData.image;
          this.userdetails.controls.insert_date.disable();
          this.userdetails.controls.name;;
          this.userdetails.controls.low_grade;
          this.userdetails.controls.high_grade;
          this.userdetails.controls.school_code.disable();;
          this.userdetails.controls.phone;
          this.userdetails.controls.principal_name;
          this.userdetails.controls.admin_email;
          this.userdetails.controls.address;
          this.userdetails.controls.update_date.disable();
          this.userdetails.controls.state;
          
          this.userdetails.controls.city;
          this.userdetails.controls.zip;
          this.userdetails.controls.districts;
          this.userdetails.patchValue(this.postData);
          

          if (response) {
            this.load = false;
          }

        }else{

        }
       
    });
    }

  onFileChange(event) {
    const reader = new FileReader();
        
    if(event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);
    
      reader.onload = () => {
   
        this.imageSrc = reader.result as string;
     
        this.img =event.target.files[0];
        
        this.userdetails.patchValue({
          fileSource: reader.result
        });
   
      };
   
    }
  }
  
  onFileChanges(event) {
    const reader = new FileReader();        
    if(event.target.files && event.target.files.length) {      

      const [file] = event.target.files;
      reader.readAsDataURL(file);
    
      reader.onload = () => {
   
     //   this.imageSrc = reader.result as string;

     this.book =event.target.files[0];

     if (event.target.files[0].type == 'application/epub+zip') {
            this.fileconvert = 'epub';
        }else{
          this.fileconvert = 'pdf';
        }
        


        this.userdetails.patchValue({
          fileSource1: reader.result
        });
   
      };
   
    }
  }
  selectChangeHandler(event: any) { 
    this.type_is = event.target.value;
    if (this.type_is == 1) {
      this.filtercategory = this.categoryFiction
    }
    else { 
      this.filtercategory = this.categoryNonFiction

    }
    console.log(this.type_is)
 }
   

  onSubmit(value){
    this.loading = false;
    var id = this.route.snapshot.paramMap.get('id');
    
    value.filetype = this.fileconvert;
      
    
    this.adminService.Editschool(this.img,value,id).subscribe((response)=>{
    
      if(response['code'] == 1){
        this.loading = true;
        this.toastr.success("School updated!");
        // this.ngOnInit();

      }else{
        this.loading = true;
        this.toastr.error("oops something went Wrong!!")

      }      

    });
   
  }

  deletepost(id){
    this.adminService.delpost(id).subscribe((response:any)=>{  
      
      if(response.code == 1){
        this.toastr.success("post Deleted!!");
        this.router.navigate(['/admin/reportedFeeds'])
      }else{
        this.toastr.error("oops something went wrong!!");
      }

    });
  }

  back(){
    this.location.back()
  }


  edit(): void {
    this.read = !this.read;

  }




}
