<div class="row">


    <div class="col-2 sm-auto border shadow-lg p-3  bg-white rounded">


        <!-- <div class="col-auto col-md-3 col-xl-2 px-sm-2 px-0 bg-light"> -->
        <div class="d-flex flex-column align-items-center align-items-sm-start px-3 pt-2 text-white">
            <img src="../../../assets/Images/iconedsoma.png" alt="Edsoma" width="200" height="70" style="border-radius: 10px;">
            <br>
            <ul class="nav nav-pills flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start" id="menu">

                <li>
                    <a routerLink='/admin/home' class="nav-link text-dark"><i class="fa fa-dashboard "></i> Dashboard</a>
                </li>


                <li>
                    <a routerLink='/admin/user' class="nav-link text-dark"><i class="fa fa-user "></i> Manage User</a>
                </li>

                <li>
                    <a href="#submenu1" data-bs-toggle="collapse" class="nav-link  text-dark">
                        <i class="fa fa-graduation-cap" aria-hidden="true"></i><i class="fa-regular fa-school"></i> Manage School <span class="d-none d-sm-inline"><i class="fa fa-caret-down"> </i></span> </a>
                    <ul class="collapse hide nav flex-column ms-1" id="submenu1" data-bs-parent="#menu">
                        
                        <li>
                            <a href="#submenu9" data-bs-toggle="collapse" class="nav-link  text-dark">
                                <i class="fa fa-dot-circle-o" aria-hidden="true"></i><i class="fa-regular fa-school"></i> School <span class="d-none d-sm-inline"><i class="fa fa-caret-down"> </i></span> </a>
                            <ul class="collapse hide nav flex-column ms-1" id="submenu9" data-bs-parent="#submenu1">
                                <li class="w-100">
                                    <a routerLink='/admin/importschool' class="nav-link text-dark px-5"> <span class="d-none d-sm-inline"><i class="fa fa-arrow-right"> </i> Import School</span> </a>
                                </li>
                                <li class="w-100">
                                    <a routerLink='/admin/showschool' class="nav-link text-dark px-5"> <span class="d-none d-sm-inline"><i class="fa fa-arrow-right"> </i> View All School</span> </a>
                                </li>
                            </ul>
                        </li>
                        
                        <li>
                            <a href="#submenu10" data-bs-toggle="collapse" class="nav-link  text-dark">
                                <i class="fa fa-dot-circle-o" aria-hidden="true"></i><i class="fa-regular fa-school"></i> Classes <span class="d-none d-sm-inline"><i class="fa fa-caret-down"> </i></span> </a>
                            <ul class="collapse hide nav flex-column ms-1" id="submenu10" data-bs-parent="#submenu1">
                                <li class="w-100">
                                    <a routerLink='/admin/importclasses' class="nav-link text-dark px-5"> <span class="d-none d-sm-inline"><i class="fa fa-arrow-right"> </i> Import Classes</span> </a>
                                </li>
                                <li class="w-100">
                                    <a routerLink='/admin/showclasses' class="nav-link text-dark px-5"> <span class="d-none d-sm-inline"><i class="fa fa-arrow-right"> </i> View All Classes</span> </a>
                                </li>
                            </ul>
                        </li>

                        <li>
                            <a href="#submenu11" data-bs-toggle="collapse" class="nav-link  text-dark">
                                <i class="fa fa-dot-circle-o" aria-hidden="true"></i><i class="fa-regular fa-school"></i> Teacher <span class="d-none d-sm-inline"><i class="fa fa-caret-down"> </i></span> </a>
                            <ul class="collapse hide nav flex-column ms-1" id="submenu11" data-bs-parent="#submenu1">
                                <li class="w-100">
                                    <a routerLink='/admin/importteacher' class="nav-link text-dark px-5"> <span class="d-none d-sm-inline"><i class="fa fa-arrow-right"> </i> Import Teacher</span> </a>
                                </li>
                                <li class="w-100">
                                    <a routerLink='/admin/showteacher' class="nav-link text-dark px-5"> <span class="d-none d-sm-inline"><i class="fa fa-arrow-right"> </i> View All Teacher</span> </a>
                                </li>
                            </ul>
                        </li>

                        <li>
                            <a href="#submenu12" data-bs-toggle="collapse" class="nav-link  text-dark">
                                <i class="fa fa-dot-circle-o" aria-hidden="true"></i><i class="fa-regular fa-school"></i> Student <span class="d-none d-sm-inline"><i class="fa fa-caret-down"> </i></span> </a>
                            <ul class="collapse hide nav flex-column ms-1" id="submenu12" data-bs-parent="#submenu1">
                                <li class="w-100">
                                    <a routerLink='/admin/importstudent' class="nav-link text-dark px-5"> <span class="d-none d-sm-inline"><i class="fa fa-arrow-right"> </i> Import Student</span> </a>
                                </li>
                                <li class="w-100">
                                    <a routerLink='/admin/showstudent' class="nav-link text-dark px-5"> <span class="d-none d-sm-inline"><i class="fa fa-arrow-right"> </i> View All Student</span> </a>
                                </li>
                            </ul>
                        </li>

                    </ul>
                </li>


                <li>
                    <a href="#submenu2" data-bs-toggle="collapse" class="nav-link  text-dark">
                        <i class="fa fa-book" aria-hidden="true"></i> Manage Books <span class="d-none d-sm-inline"><i class="fa fa-caret-down"> </i></span> </a>
                    <ul class="collapse hide nav flex-column ms-1" id="submenu2" data-bs-parent="#menu">
                        <li class="w-100">
                            <a routerLink='/admin/addbook' class="nav-link text-dark px-5"> <span class="d-none d-sm-inline"><i class="fa fa-arrow-right"> </i> Add Books</span> </a>
                        </li>
                        <li class="w-100">
                            <a routerLink='/admin/book' class="nav-link text-dark px-5"> <span class="d-none d-sm-inline"><i class="fa fa-arrow-right"> </i> View All Books</span> </a>
                        </li>
                        <li>
                            <a routerLink='/admin/category' class="nav-link text-dark px-5"> <span class="d-none d-sm-inline"><i class="fa fa-arrow-right"> </i> Add Category</span> </a>

                        </li>
                    </ul>
                </li>

                
                <li>
                    <a href="#submenu4" data-bs-toggle="collapse" class="nav-link  text-dark">
                        <i class="fa fa-book" aria-hidden="true"></i> Manage Events <span class="d-none d-sm-inline"><i class="fa fa-caret-down"> </i></span> </a>
                    <ul class="collapse hide nav flex-column ms-1" id="submenu4" data-bs-parent="#menu">
                        <li class="w-100">
                            <a routerLink='/admin/addevent' class="nav-link text-dark px-5"> <span class="d-none d-sm-inline"><i class="fa fa-arrow-right"> </i> Add Events</span> </a>
                        </li>
                        <li class="w-100">
                            <a routerLink='/admin/event' class="nav-link text-dark px-5"> <span class="d-none d-sm-inline"><i class="fa fa-arrow-right"> </i> View All Events</span> </a>           
                        </li>
                    </ul>
                </li>


                <li>
                    <a href="#submenu3" data-bs-toggle="collapse" class="nav-link  text-dark">
                        <i class="fa fa-book" aria-hidden="true"></i> Notification <span class="d-none d-sm-inline"><i class="fa fa-caret-down"> </i></span></a>
                    <ul class="collapse hide nav flex-column ms-1" id="submenu3" data-bs-parent="#menu">
                        <li class="w-100">
                            <a routerLink='/admin/push' class="nav-link text-dark px-5"> <span class="d-none d-sm-inline"><i class="fa fa-arrow-right"> </i> Push notification</span> </a>
                        </li>
                        <li class="w-100">
                            <a routerLink='/admin/email' class="nav-link text-dark px-5"> <span class="d-none d-sm-inline"><i class="fa fa-arrow-right"> </i> Email notification</span> </a>
                        </li>
                    </ul>
                </li>
                <li>
                    <a href="#submenu8" data-bs-toggle="collapse" class="nav-link text-dark">
                        <i class="fa fa-file-text-o" aria-hidden="true"></i> Pages <span class="d-none d-sm-inline"><i class="fa fa-caret-down"> </i></span></a>
                    <ul class="collapse hide nav flex-column ms-1" id="submenu8" data-bs-parent="#menu">
                        <li class="w-100">
                            <a routerLink='/admin/aboutus' class="nav-link text-dark px-5"> <span class="d-none d-sm-inline"><i class="fa fa-arrow-right"> </i> About us </span> </a>
                        </li>
                        <li>
                            <a routerLink='/admin/privacy' class="nav-link text-dark px-5"> <span class="d-none d-sm-inline"><i class="fa fa-arrow-right"> </i> Privacy Policy</span> </a>
                        </li>
                        <li>
                            <a routerLink='/admin/terms' class="nav-link text-dark px-5"> <span class="d-none d-sm-inline"><i class="fa fa-arrow-right"> </i> Terms and Condition</span> </a>
                        </li>
                        <li>
                            <a routerLink='/admin/faq' class="nav-link text-dark px-5"> <span class="d-none d-sm-inline"><i class="fa fa-arrow-right"> </i> FAQ</span> </a>
                        </li>

                    </ul>
                </li>

                <li>
                    <a routerLink='/admin/changepassword' class="nav-link text-dark"><i class="fa fa-lock "></i> Change password</a>
                </li>


                <li>
                    <a href="#" class="nav-link text-dark" data-bs-toggle="modal" data-bs-target="#logoutModal"><i class="fa fa-sign-out" aria-hidden="true"></i>  Logout</a>
                </li>


            </ul>
            <hr>

        </div>
        <!-- </div> -->


    </div>






    <div class="col-10 mt-4">
        <!-- <div style="height: 700px;overflow-y: scroll;"> -->
        <div class="full-height" style="overflow-y: scroll;">

            <router-outlet></router-outlet>

        </div>

    </div>
</div>







<!-- <div class="container-fluid">
    <div class="row flex-nowrap">
        <div class="col-auto col-md-3 col-xl-2 px-sm-2 px-0 bg-light">
            <div class="d-flex flex-column align-items-center align-items-sm-start px-3 pt-2 text-white min-vh-100">
                <img src="../../../assets/Images/iconedsoma.png" alt="Edsoma" width="200" height="70" style="border-radius: 10px;">
                <br>
                <ul class="nav nav-pills flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start" id="menu">

                    <li>
                        <a routerLink='/admin/home' class="nav-link text-dark"><i class="fa fa-dashboard "></i> Dashboard</a>
                    </li>


                    <li>
                        <a routerLink='/admin/user' class="nav-link text-dark"><i class="fa fa-user "></i> Manage User</a>
                    </li>


                    <li>
                        <a href="#submenu1" data-bs-toggle="collapse" class="nav-link  text-dark">
                            <i class="fa fa-book" aria-hidden="true"></i> Manage Books</a>
                        <ul class="collapse show nav flex-column ms-1" id="submenu1" data-bs-parent="#menu">
                            <li class="w-100">
                                <a routerLink='/admin/addbook' class="nav-link text-dark px-5"> <span class="d-none d-sm-inline"><i class="fa fa-arrow-right"> </i> Add Books</span> </a>
                            </li>
                            <li class="w-100">
                                <a routerLink='/admin/book' class="nav-link text-dark px-5"> <span class="d-none d-sm-inline"><i class="fa fa-arrow-right"> </i> View All Books</span> </a>
                            </li>
                            <li>
                                <a routerLink='/admin/category' class="nav-link text-dark px-5"> <span class="d-none d-sm-inline"><i class="fa fa-arrow-right"> </i> Add Category</span> </a>

                            </li>
                        </ul>
                    </li>


                    <li>
                        <a href="#submenu2" data-bs-toggle="collapse" class="nav-link text-dark">
                            <i class="fa fa-file-text-o" aria-hidden="true"></i> Pages </a>
                        <ul class="collapse show nav flex-column ms-1" id="submenu2" data-bs-parent="#menu">
                            <li class="w-100">
                                <a routerLink='/admin/aboutus' class="nav-link text-dark px-5"> <span class="d-none d-sm-inline"><i class="fa fa-arrow-right"> </i> About us </span> </a>
                            </li>
                            <li>
                                <a routerLink='/admin/privacy' class="nav-link text-dark px-5"> <span class="d-none d-sm-inline"><i class="fa fa-arrow-right"> </i> Privacy Policy</span> </a>
                            </li>
                            <li>
                                <a routerLink='/admin/terms' class="nav-link text-dark px-5"> <span class="d-none d-sm-inline"><i class="fa fa-arrow-right"> </i> Terms and Condition</span> </a>
                            </li>
                            <li>
                                <a routerLink='/admin/faq' class="nav-link text-dark px-5"> <span class="d-none d-sm-inline"><i class="fa fa-arrow-right"> </i> FAQ</span> </a>
                            </li>

                        </ul>
                    </li>

                    <li>
                        <a routerLink='/admin/changepassword' class="nav-link text-dark"><i class="fa fa-lock "></i> Change password</a>
                    </li>


                    <li>
                        <a href="#" class="nav-link text-dark" data-bs-toggle="modal" data-bs-target="#logoutModal"><i class="fa fa-sign-out" aria-hidden="true"></i>  Logout</a>
                    </li>


                </ul>
                <hr>

            </div>
        </div>
        <div class="col py-3" style="max-height:1000px; overflow: scroll;">
            <router-outlet></router-outlet>

        </div>
    </div>
</div> -->







<div id="logoutModal" class="modal modal-message modal-success fade" style="display: none;" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <i class="glyphicon glyphicon-check "></i>
            </div>
            <div class="modal-title text-center" style="font-size: larger;">Logout Admin</div>
            <div class="modal-body">Are You Sure Want to Logout ?</div>
            <div class="modal-footer">
                <button type="button" class="btn btn-success" data-bs-dismiss="modal">Cancel</button>
                <button type="button" class="btn btn-success" (click)="logout()" data-bs-dismiss="modal">Confirm</button>

            </div>
        </div>
    </div>
</div>