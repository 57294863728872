import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AdminService } from 'src/app/Service/admin.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  constructor(private adminservie:AdminService,private toastr: ToastrService,private router: Router) { }

  ngOnInit(): void {
    if (sessionStorage.getItem('token')) {
      this.router.navigate(['admin/home']);
    }
  }


  onSubmit(value:any){
    this.adminservie.Login(value).subscribe(
      (response) => {
        if(response['code'] == 1){    
          sessionStorage.setItem('token', response['data']);
          this.toastr.success("Welcome Admin","Login Success");
          this.router.navigate(['/admin/home'])
        }else{
          this.toastr.error("Invalid Credentials !!", "Login Fails")
          this.ngOnInit();
        }
      
      });
  }



}
