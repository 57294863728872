import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { AdminService } from 'src/app/Service/admin.service';

@Component({
  selector: 'app-rfeeds',
  templateUrl: './rfeeds.component.html',
  styleUrls: ['./rfeeds.component.css']
})
export class RfeedsComponent implements OnInit,AfterViewInit {

  dtOptions: any = {};
  dtTrigger: Subject<any> = new Subject<any>();
  FeedData: any;
  feed = true;
  
  constructor(private adminService: AdminService,private route:Router,private toastr: ToastrService,@Inject(HttpClient) private httpClient: HttpClient) { }

  ngOnInit(){

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      lengthMenu: [5, 10, 15, 20, 25, 30],
      processing: true,
      responsive:true

    };

    this.adminService.Reportedfeeds().subscribe(
      (response: any) => {
        
        this.FeedData = response.data;
        
      });

  }


  ngAfterViewInit(): void { 
        
    setTimeout(() => {
        this.dtTrigger.next();
        this.feed = false;
      }, 500);

  }


  

  deletereport(id){
    this.adminService.deletereport(id).subscribe((response)=>{
      if(response['code'] == 1){
        this.toastr.success("Removed!!");
        this.ngOnInit()
      }else{
        this.toastr.error("oops something went Wrong!!");
      }
  });
    
  }
}
