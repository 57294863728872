import { ThisReceiver } from '@angular/compiler';
import { HttpClient } from '@angular/common/http';
import { Subject  } from 'rxjs';

import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AdminService } from 'src/app/Service/admin.service';
import { environment } from 'src/environments/environment';
import { Location } from '@angular/common'
import { DatePipe } from '@angular/common'
import * as XLSX from 'xlsx';
import { format } from 'date-fns';
import { saveAs } from 'file-saver';
import { MatNoDataRow, MatTableDataSource } from '@angular/material/table';
// import { log } from 'console';

@Component({
  selector: 'app-importclasses',
  templateUrl: './importclasses.component.html',
  styleUrls: ['./importclasses.component.css']
})

export class ImportClassesComponent implements OnInit {
  minCurrent: any;
  actualDate: any;
  activeDate: any;
  public file: any;

  path = environment.bucket;
  postData: any;

  user = true;
  image: any;
  read = true;

  load = false;
  selectedSchool = ""

  filtercategory: any;
  filterage: any;
  filterlang: any;
  filtertype: any;
  selectSchool: any = []

  loading = true;
  imgURL: any = "assets/Images/Event.png";
  public imagePath: any;
  public message: any;
  public img: any;
  imageSrc: string;
  book_url: any;
  fileconvert: any;
  book: any;
  updatedMinCurrentForActual: any;
  updatedMaxCurrentForActual: any;
  updatedMinCurrentForActive: any;
  updatedMaxCurrentForActive: any;
  excelData: any = [];
  file_name: any;
  show = false;
  filename: any;	
  displayedColumns: string[] = ['course_id', 'class_name','section','grade', 'start_date', 'end_date', 'school_code', 'class_info','data_status'];
  dataSource: any;

  formattedStartDate: string;
  formattedEndDate: string;


  constructor(private datePipe: DatePipe, private route: ActivatedRoute, private router: Router, private adminService: AdminService, private toastr: ToastrService, private location: Location) { }

  userdetails = new FormGroup({
    file: new FormControl('', Validators.required),


  });


  ngOnInit(): void {
    console.log(555)
    this.adminService.getAllSchool().subscribe((response: any) => {
      console.log("665656", response.data)
      this.selectSchool = response.data
      if (this.selectSchool.length>= 1) {
        this.read = false
        this.selectedSchool=this.selectSchool[0].school_code
        

        console.log("selectedSchool",this.selectedSchool);
        
      }
    })




  }
  actualOnChange(event: any) {
    console.log("actualOnChange");
    this.actualDate = event.target.value

    this.updatedMaxCurrentForActive = this.actualDate
    if (this.activeDate == '') {
      this.activeDate = undefined

    }
    else if (this.activeDate == undefined) {
      this.updatedMaxCurrentForActive = this.actualDate
    }
    else if (this.activeDate > this.actualDate) {
      this.toastr.error("Actual Date & Time  should be greater then Active Time")
    }

    this.actualDate = event.target.value
    console.log(event.target.value)
  }
  activeOnChange(event: any) {
    console.log(5556, event.target.value)
    console.log("activeOnChange");
    this.activeDate = event.target.value

    this.updatedMinCurrentForActual = this.activeDate
    console.log(this.updatedMinCurrentForActual, this.activeDate);
    if (this.activeDate == '') {
      this.activeDate = undefined

    }
    else if (this.actualDate == undefined) {
      this.updatedMinCurrentForActual = this.activeDate
    }
    else if (this.updatedMinCurrentForActual > this.activeDate) {
      this.toastr.error("Time should be correct")
    }


    else if (this.actualDate < this.activeDate) {
      console.log("3543")
      this.toastr.error("Active Date can't be greater than Actual Date!!")
    }

  }

  convertedArray: any = []
  onFileChangeBook(event) {
    console.log(this.selectedSchool, 5656566)
    const reader = new FileReader();
    //  let file_name;
    if (event.target.files && event.target.files.length) {

      const [file] = event.target.files;
      this.filename = event.target.files[0];
      console.log("file=>", file);
      reader.readAsDataURL(file);

      reader.onload = () => {


        this.file = event.target.files[0];
        console.log("event.target.files[0]", event.target.files[0].name);

        this.userdetails.patchValue({
          fileSource: reader.result
        });
      };



    }

    const file = event.target.files[0];
    this.file_name = event.target.files[0];

    if (file) {
      const fileReader = new FileReader();
      fileReader.onload = (e) => {
        const arrayBuffer: any = fileReader.result;
        const data = new Uint8Array(arrayBuffer);
        const workbook = XLSX.read(data, { type: 'array' });
        const firstSheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[firstSheetName];
        console.log(77, worksheet)
        this.excelData = XLSX.utils.sheet_to_json(worksheet,{rawNumbers:false});

        this.convertedArray = this.excelData.map((item: any) => {
          console.log(item.school_code,this.selectedSchool)
         
          return {
            course_id: item.course_id,
            class_name: item.class_name,
            section: item.section,
            grade: item.grade,
            display_start_date: format(new Date(item.start_date), 'MM/dd/yy'),
            display_end_date: format(new Date(item.end_date), 'MM/dd/yy'),
            start_date: format(new Date(item.start_date), 'yyyy-MM-dd'),
            end_date: format(new Date(item.end_date), 'yyyy-MM-dd'),
            data_status: item.school_code==this.selectedSchool?"Valid":"In-Valid",
            school_code: item.school_code,
            class_info: item.class_info,
            
          }
        })

        console.log("convertedArray",this.convertedArray)
      
        this.dataSource = new MatTableDataSource(this.convertedArray)
        this.dataSource = this.dataSource.filteredData
        
      };
      fileReader.readAsArrayBuffer(file);
      this.show = true;
    }

  }
  formatDate(dateNumeric: number): string {
    const date = new Date(0);
    date.setUTCDate(dateNumeric);
    console.log("date",date.toISOString().split('T')[0]);
    
    return date.toISOString().split('T')[0];
  }

  numericValueToDate(numericValue) {
    const referenceDate = new Date(1900, 0, 1);
    const days = Math.floor(numericValue);
    const fractionOfDay = numericValue - days;

    const millisecondsInDay = 24 * 60 * 60 * 1000;
    const millisecondsToAdd = Math.round(fractionOfDay * millisecondsInDay);

    const resultDate = new Date(referenceDate.getTime() + (days * millisecondsInDay) + millisecondsToAdd);
    let date=resultDate.getFullYear()+"/"+resultDate.getMonth()+"/"+resultDate.getDate()
    // console.log("numericValueToDate -> ",date);
    
    return {dataDate:resultDate.toISOString().split('T')[0],indexDate:date};
  }
  getHeaders(row: any) {
    console.log(row);
    // return Object.keys(row);
  }
  onFileChangeImage(event) {
    const reader = new FileReader();
    console.log("onFileChangeImage");
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);

      reader.onload = () => {



        this.book = event.target.files[0];
        this.userdetails.patchValue({
          fileSource1: reader.result
        });

      };

    }
  }

  reset(){

    this.userdetails.reset();
    this.imageSrc = null
    this.show = false
    this.dataSource=null;

}

  onSubmit(value) {

    
    let check = true;



    if (!this.userdetails.valid) {
      this.toastr.error("All fields are required")
    }
    else {
      let m=this.dataSource.map(e => {
        if (e.school_code != this.selectedSchool) {

          check = false
        }
        return e
      })
      if (!check) {
        this.toastr.error("Classes have different Schools")
      } else {

        this.adminService.ImportClasses(m).subscribe((response) => {

          console.log("response",response);

        this.convertedArray = response["data"].map((item: any) => {
          let x=new Date(item.birth_date) ;
          
 
          return {
            course_id: item.course_id,
            class_name: item.class_name,
            section: item.section,
            grade: item.grade,
            display_start_date: format(new Date(item.start_date), 'MM/dd/yy'),
            display_end_date: format(new Date(item.end_date), 'MM/dd/yy'),
            start_date: format(new Date(item.start_date), 'yyyy-MM-dd'),
            end_date: format(new Date(item.end_date), 'yyyy-MM-dd'),
            data_status: item.school_code==this.selectedSchool?"Valid":"In-Valid",
            school_code: item.school_code,
            class_info: item.class_info,
        }
      })

    
      this.dataSource = new MatTableDataSource(this.convertedArray)
      this.dataSource = this.dataSource.filteredData
      console.log(this.dataSource,"calling api")
          const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.convertedArray);
          const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
          const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      
          const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          saveAs(blob, 'classes_file.xlsx');
        


          if (response['code'] == 1) {
            this.loading = true;
            this.toastr.success("Classes Added!");
            this.ngOnInit();
            this.userdetails.reset();

          } else {
            this.loading = true;
            this.toastr.error("oops something went Wrong!!")

          }

        });
      }

    }
  }

  onSelect(value) {
    console.log(999,this.selectedSchool)
    this.read = false;
    this.reset()
  }

  back() {
    this.location.back()
  }

}






