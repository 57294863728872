<div class="col-md-12 col-sm-12 col-xs-12">
    <div class="container">
        <div class="col-md-12">
            <h2 class="text-dark">
                <i class="fa fa-graduation-cap" aria-hidden="true"></i> Show All School
            </h2>
            <hr>
        </div>
    </div>
</div>


<div class="card">
    <div class="card-header">
        School Mangement

        <a class="btn btn-outline-light md-block text-dark" (click)="reload()" routerLinkActive="active "> <i class="fa fa-refresh fa-lg" aria-hidden="true"></i></a>

    </div>
    <div class="card-body">



        <div class="container-fluid" style="background-color:white; padding: 20px;">

            <div *ngIf='feed' class="d-flex justify-content-center">
                <div class="spinner-border" role="status">
                </div>
            </div>


            <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-striped table-bordered table-sm row-border hover" style="width: 100%; font-size:medium;">
                <thead *ngIf='!feed'> 
                    <tr>
                        <th> id </th>
                        <th> name </th>
                        <th>image</th>
                        <th>low_grade</th>
                        <th>high_grade</th>
                        <th>school_code</th>
                        <th>phone</th>
                        <th>principal_name</th>
                        <th>admin_email</th>
                        <th>address</th>
                        <th>city</th>
                        <th>state</th>
                        <th>zip</th>
                        <th>districts</th>
                        <th>action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let datas of feedData">
                        <td>{{ datas.id }}</td>                        
                        <td>{{ datas.name }}</td>
                        <td> <img [src]=datas.image alt="img" width="150px" height="150px" style="padding: 10px;"></td>
                        <td>{{ datas.low_grade }}</td>
                        <td>{{ datas.high_grade }}</td>
                        <td>{{ datas.school_code }}</td>
                        <td>{{ datas.phone }}</td>
                        <td>{{ datas.principal_name }}</td>
                        <td>{{ datas.admin_email }}</td>
                        <td>{{ datas.address }}</td>
                        <td>{{ datas.city }}</td>
                        <td>{{ datas.state }}</td>
                        <td>{{ datas.zip}}</td>
                        <td>{{ datas.districts}}</td>

                        <td>
                            <a mwlConfirmationPopover popoverTitle="Logout" popoverMessage="Want to remove School ?" placement="left" (confirm)="deletepost(datas.id)" (cancel)="false">
                                <i class="fa fa-trash-o fa-lg " style="color:brown; " aria-hidden="true "></i></a>&nbsp;&nbsp;&nbsp;&nbsp;

                            <a [routerLink]="[ '/admin/viewschool',datas.id] "> <i class="fa fa-edit fa-lg " style="color: green; " aria-hidden="true "></i></a>
                            <hr>
                            <a [href]="datas.book_url"> <i class="fa fa-download fa-lg " style="color:blue; " aria-hidden="true "></i></a>

                        </td>

                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>