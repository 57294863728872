<div class="col-md-12 col-sm-12 col-xs-12">
    <div class="container">
        <div class="col-md-12">
            <h2 class="text-dark">
                <i class="fa fa-camera"></i> Manage Events
            </h2>
            <hr>
        </div>
    </div>
</div>


<div class="card">
    <div class="card-header">
        Event Mangement

        <a class="btn btn-outline-light md-block text-dark" (click)="reload()" routerLinkActive="active "> <i class="fa fa-refresh fa-lg" aria-hidden="true"></i></a>

    </div>
    <div class="card-body">



        <div class="container-fluid" style="background-color:white; padding: 20px;">

            <div *ngIf='feed' class="d-flex justify-content-center">
                <div class="spinner-border" role="status">
                </div>
            </div>


            <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger " class="table table-striped table-bordered table-sm row-border hover" style="width: 100%; font-size:medium;">
                <thead *ngIf='!feed'>
                    <tr>
                        <th> ID </th>
                        <th> Book Image </th>
                        <th>Event Title</th>
                        <th>Author Image</th>
                        <th>Event Link</th>
                        <th>Active Date</th>
                        <th>Actual Date</th>
                        <th>Inserted Date</th>
                     
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let datas of feedData">
                        <td>{{ datas.id }}</td>
                        <td> <img [src]=datas.bookImg alt="img" width="150px" height="150px" style="padding: 10px;"></td>

                        <td>{{ datas.eventTitle }}</td>
                        <td> <img [src]=datas.authorImg alt="img" width="150px" height="150px" style="padding: 10px;"></td>
                        <td>{{ datas.eventLink }}</td>
                        <td>{{ datas.activeDate}}</td>
                        <td>{{ datas.actualDate}}</td>
                        <td>{{ datas.createdDate}}</td>
                        

                        <td>
                            <a mwlConfirmationPopover popoverTitle="Delete Event" popoverMessage="Want to delete Event?" placement="left" (confirm)="deletepost(datas.id)" (cancel)="false">
                                <i class="fa fa-trash-o fa-lg " style="color:brown; " aria-hidden="true "></i></a>&nbsp;&nbsp;&nbsp;&nbsp;
                                <a [routerLink]="[ '/admin/viewevent',datas.id] "> <i class="fa fa-edit fa-lg " style="color: green; " aria-hidden="true "></i></a>

                           

                        </td>

                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>