
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AdminService } from 'src/app/Service/admin.service';
import { Location } from '@angular/common'
import {environment } from '../../../environments/environment'
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common'


@Component({
  selector: 'app-viewevent',
  templateUrl: './viewevent.component.html',
  styleUrls: ['./viewevent.component.css']
})
export class VieweventComponent implements OnInit {

  path = environment.bucket ;
  postData: any;
  
  user = true;
  image: any;
  read = true;


 
  filtercategory: any;
  filterage: any;
  filterlang: any;
  filtertype: any;

  updatedMinCurrentForActual: any;
  updatedMaxCurrentForActual: any;
  updatedMinCurrentForActive: any;
  updatedMaxCurrentForActive: any;

  minCurrent: any;
  actualDate: any;
  activeDate: any;


  loading = true;
  imgURL: any = "assets/Images/uploadimage.png";
  public imagePath: any;
  public message: any;
  public img: any;
  imageSrcforBook: string;
  imageSrcforAuthor: string;
  book_url: any;
  fileconvert:any;
  book: any;
  load = true;
  history_data:any;
  // preview(files:any) {
  //   if (files.length === 0)
  //     return;
 
  //   var mimeType = files[0].type;
  //   if (mimeType.match(/image\/*/) == null) {
  //     this.message = "Only images are supported.";
  //     return;
  //   }

 
  //   var reader = new FileReader();
  //   this.imagePath = files;
  //      this.img =files[0];

    
  //   reader.readAsDataURL(files[0]);
  //   reader.onload = (_event) => {
  //     this.imgURL = reader.result;
      
  //   }
  // }


  constructor(private datePipe : DatePipe,private route: ActivatedRoute, private router: Router, private adminService: AdminService, private toastr: ToastrService, private location: Location) { }
  eventDetails = new FormGroup({
    eventTitle: new FormControl('', Validators.required),
    eventLink: new FormControl('', Validators.required),
    authorImg: new FormControl('', Validators.required),
    bookImg: new FormControl(),
    activeDate: new FormControl(),
    actualDate: new FormControl('', Validators.required),
    createdDate: new FormControl('', Validators.required),
    updatedDate: new FormControl('', Validators.required),
    
    file: new FormControl(),
    fileSource: new FormControl(),
    file1: new FormControl(),
    fileSource1: new FormControl(),


  });


  ngOnInit(): void {
    const id = this.route.snapshot.paramMap.get('id');

    this.adminService.Viewevent(id).subscribe(
      (response:any)=>{
        
        this.postData = response.data['event_detail'][0];
        this.history_data = response.data['history'];
          if (response.data['history'] == null) {
            this.history_data  = false
          }
        console.log(this.postData)
        
        
          // id: 31,
          // eventTitle: 'Test For Sql',
          // eventLink: 'https://www.google.com/',
          // authorImg: '1664781369407appLogo@2x-1.png',
          // bookImg: '1664781369408appLogo@3x-1.png',
          // activeDate: '2022-10-03 12:46:00.000000',
          // actualDate: '2022-10-05 12:46:00.000000',
          // createdDate: '2022-10-03 12:46:11',
          // updatedDate: '2022-10-03 12:46:11',
          // isActualDone: 1,
          // isActiveDone: 0

        if(response.code == 1){

          this.user = false;
          this.image = this.postData.bookImg;
          this.book_url = environment.books_data + this.postData.authorImg;
          this.image = environment.books_data+this.postData.bookImg;
console.log(this.book_url)
          this.eventDetails.controls.updatedDate.disable();;
          this.eventDetails.controls.eventTitle;
          this.eventDetails.controls.createdDate.disable();
          this.eventDetails.controls.eventLink;
          this.eventDetails.controls.activeDate;
          this.eventDetails.controls.actualDate;
          this.postData.activeDate = this.datePipe.transform(this.postData.activeDate, 'YYYY-MM-ddThh:mm');
          this.postData.actualDate = this.datePipe.transform(this.postData.actualDate, 'YYYY-MM-ddThh:mm');

          this.updatedMaxCurrentForActive = this.postData.actualDate
          this.updatedMinCurrentForActual=this.postData.activeDate
          this.eventDetails.patchValue(this.postData);

          if (response) {
            this.load = false;
          }

        }else{

        }
       
    });



    // this.adminService.filterdata().subscribe(
    //   (response:any)=>{
    //     this.filtercategory = response.data['category'];
    //     this.filterage = response.data['age_range'];
    //     this.filterlang = response.data['language'];
    //     this.filtertype = response.data['type'];
                

    // });

  }

  actualOnChange(event: any) { 

    this.actualDate = event.target.value
    
    this.updatedMaxCurrentForActive=this.actualDate   
    if (this.activeDate =='') { 
      this.activeDate=undefined

    }
    else if (this.activeDate == undefined) { 
      this.updatedMaxCurrentForActive=this.actualDate   
    }
    else if (this.activeDate > this.actualDate) { 
      this.toastr.error("Actual Date & Time  should be greater then Active Time")
    }

    this.actualDate=event.target.value
console.log(event.target.value)
  }
  activeOnChange(event: any) { 
    console.log(5556, event.target.value)
   
    this.activeDate = event.target.value
   
    this.updatedMinCurrentForActual =  this.activeDate
    console.log(this.updatedMinCurrentForActual, this.activeDate);
    if (this.activeDate == '') { 
      this.activeDate=undefined

    }
    else if (this.actualDate == undefined) { 
      this.updatedMinCurrentForActual=this.activeDate
    }
    else if (this.updatedMinCurrentForActual > this.activeDate) { 
      this.toastr.error("Time should be correct")
    }
    

    else if (this.actualDate < this.activeDate) { 
      console.log("3543")
      this.toastr.error("Active Date can't be greater than Actual Date!!")
    }

  }

  onFileChange(event) {
    const reader = new FileReader();
        
    if(event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);
    
      reader.onload = () => {
   
        this.imageSrcforBook = reader.result as string;
     
        this.img =event.target.files[0];
        
        this.eventDetails.patchValue({
          fileSource: reader.result
        });
   
      };
   
    }
  }
  
  onFileChanges(event) {
    const reader = new FileReader();        
    if(event.target.files && event.target.files.length) {      

      const [file] = event.target.files;
      reader.readAsDataURL(file);
    
      reader.onload = () => {
   
       this. imageSrcforAuthor = reader.result as string;

     this.book =event.target.files[0];


        


        this.eventDetails.patchValue({
          fileSource1: reader.result
        });
   
      };
   
    }
  }
   

  onSubmit(value) {
    
    if (this.activeDate > this.actualDate) {
      this.toastr.error("Actual Date & Time should be greater then Active Time")
    }
    else if (this.updatedMinCurrentForActual > this.activeDate) {
      this.toastr.error("Time should be correct")
    }
    

    else if (this.actualDate < this.activeDate) {
      console.log("3543")
      this.toastr.error("Active Date can't be greater than Actual Date!!")
    } else { 
      let activeDateCheck = new Date(value.activeDate);
      activeDateCheck.setHours(activeDateCheck.getHours() - 5);
      activeDateCheck.setMinutes(activeDateCheck.getMinutes() - 30);

      let actualDateCheck = new Date(value.actualDate);
      actualDateCheck.setHours(actualDateCheck.getHours() - 5);
      actualDateCheck.setMinutes(actualDateCheck.getMinutes() - 30);

      console.log(556, this.datePipe.transform(activeDateCheck.toUTCString(), 'YYYY-MM-ddThh:mm'))
      console.log(556, this.datePipe.transform(actualDateCheck.toUTCString(), 'YYYY-MM-ddThh:mm'))
      value.activeDate = this.datePipe.transform(activeDateCheck.toUTCString(), 'YYYY-MM-ddThh:mm')
      value.actualDate=this.datePipe.transform(actualDateCheck.toUTCString(), 'YYYY-MM-ddThh:mm')
      this.loading = false;
      var id = this.route.snapshot.paramMap.get('id');
      
      value.filetype = this.fileconvert;
        
      
      this.adminService.Editevent(this.img,this.book,value,id).subscribe((response)=>{
      
        if(response['code'] == 1){
          this.loading = true;
          this.toastr.success("Event updated!");
          this.ngOnInit();
  
        }else{
          this.loading = true;
          this.toastr.error("oops something went Wrong!!")
  
        }      
  
      });
    }
   
   
  }

  deletepost(id){
    this.adminService.delpost(id).subscribe((response:any)=>{  
      
      if(response.code == 1){
        this.toastr.success("post Deleted!!");
        this.router.navigate(['/admin/reportedFeeds'])
      }else{
        this.toastr.error("oops something went wrong!!");
      }

    });
  }

  back(){
    this.location.back()
  }


  edit(): void {
    this.read = !this.read;

  }

}
