<div class="col-md-12 col-sm-12 col-xs-12">
    <div class="container">
        <div class="col-md-12">
            <h2 class="text-dark">
                <i class="fa fa-lock"></i> Change Password
            </h2>
            <hr>
        </div>
    </div>
</div>

<div class="container mt-5 card" style="width: 700px; ">



    <div class="card-header">
        <b>Chnage Password</b>
    </div>
    <form #ResetForm="ngForm" (ngSubmit)="onSubmitReset(ResetForm.value)" class="form-signin card-body">
        <label for="oldpassword" class="form-label"> Old password :</label>
        <input type="password" name="oldpassword" required [class.is-invalid]="oldpassword.invalid && oldpassword.touched" ngModel #oldpassword="ngModel" class="form-control mb-2" placeholder="old Password">
        <pre class="text-danger" [class.d-none]="oldpassword.valid || oldpassword.untouched ">Old Password field is required </pre>

        <label for="newpassword" class="form-label"> New password :</label>
        <input type="password" name="password" required pattern="[a-z0-9A-Z]{6,}" [class.is-invalid]="password.invalid && password.touched" ngModel #password="ngModel" class="form-control mb-2" placeholder="New Password">
        <pre class="text-danger" [class.d-none]="password.valid || password.untouched ">Password field is required </pre>
        <pre class="text-danger" *ngIf="password.hasError('pattern')">Password must be at least 6 characters </pre>

        <label for="cpassword" class="form-label"> Confirm new password :</label>
        <input type="password" name="cpassword" required ngValidateEqual="password" [class.is-invalid]="cpassword.invalid && cpassword.touched" ngModel #cpassword="ngModel" class="form-control mb-2" placeholder=" Confirm New Password">
        <pre class="text-danger" [class.d-none]="cpassword.valid || cpassword.untouched ">Confirm Password field is required </pre>
        <pre class="text-danger" *ngIf="cpassword.hasError('notEqual')">Passwords must match   </pre>

        <br>
        <button class="btn btn-primary btn-block mb-1" type="submit" [disabled]="!ResetForm.valid">Chnage password <i class="fa fa-sign-in" aria-hidden="true"></i>
        </button>
        <br>



    </form>

</div>