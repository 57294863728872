<div class="col-md-12 col-sm-12 col-xs-12">
    <div class="container">
        <div class="col-md-12">
            <h2 class="text-dark">
                <i class="fa fa-cube"></i> View/Edit Category
            </h2>

            <hr>
        </div>
    </div>
</div>

<div class="container-fluid" style="background-color:white; padding: 20px;">

    <div class="card border-dark">
        <div class="card-header border-dark">
            Edit Category
            <h5 style="float: right;">
                <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                    <a class="btn btn-outline-light md-block text-dark" (click)="back()" routerLinkActive="active "> <i class="fa fa-arrow-left fa-lg" aria-hidden="true"></i>&nbsp; Back &nbsp;&nbsp;&nbsp;</a>
                </div>
            </h5>
        </div>

        <div class="card-body">

            <form #categoryForm="ngForm" (ngSubmit)="onSubmit(categoryForm.value)">

                <div class="row">
                    <div class="col-6">
                        <div class="picture-container">
                            <div class="picture">


                                <input #file type="file" #pic="ngModel" accept="image/*" ngModel name="pic" id="wizard-picture" (change)="preview(file.files)">
                                <img [src]="imgURL" height="200px" width="350px">
                            </div>
                            <pre class="">Choose Category Image </pre>
                        </div>
                    </div>
                    <div class="col-5">
                        <label for="name" class="form-label">Category Name :</label>
                        <input type="text" name="name" [ngModel]="names" [class.is-invalid]="name.invalid && name.touched" maxlength="50" required #name="ngModel" ngModel class="form-control" placeholder="Category Name">
                        <pre class="text-danger" [class.d-none]="name.valid || name.untouched ">Category Name field is required </pre>
                        <br>
                        
                        <form [formGroup]="userdetails" >
                            <div class="col">
                                <label for="type_id">Type:</label>
                                <select formControlName="type_id" class="form-control">  
                                    <option [ngValue]="null" disabled>Choose type</option>

                                    <option *ngFor="let order of filtertype; let i = index" [value]="filtertype[i].typeId" >
                                        {{filtertype[i].type_name}}
                                      </option>
                                
                                </select>
                            </div>
                    </form>
                    </div>
                </div>

                <div *ngIf='loading' style="float: right;">
                    <button type="submit" class="btn btn-outline-primary" [disabled]="!categoryForm.valid"> Edit Category</button>
                </div>
                <div *ngIf='!loading' style="float: right;">
                    <button class="btn btn-primary" type="button" disabled>
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Loading...
                    </button>
                </div>
            </form>

        </div>
    </div>
</div>