import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { AdminService } from 'src/app/Service/admin.service';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})
export class UserComponent implements OnInit ,AfterViewInit{
  
  dtOptions: any = {};
  dtTrigger: Subject<any> = new Subject<any>();
  userData: any;
  user = true;
  constructor(private adminService: AdminService,private route:Router,private toastr: ToastrService,@Inject(HttpClient) private httpClient: HttpClient) { }

  ngOnInit(){

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      lengthMenu: [10, 15, 20, 25, 30],
      processing: true,
      
      dom: 'Bfltip',      
      buttons: [
        'print',
        'excel',
       
      ],
      responsive: true

    };

    this.adminService.Userdata().subscribe(
      (response: any) => {
        this.userData = response.data;
     
        
      });

  }


  ngAfterViewInit(): void { 
        
    setTimeout(() => {
        this.dtTrigger.next();
        this.user = false;
      }, 500);

  }

  deleteuser(id:any){
      this.adminService.deluser(id).subscribe((response:any)=>{  
        
      if(response.code == 1){ 
        this.toastr.success("User Deleted!!");
        this.ngOnInit()
      }else{
        this.toastr.error("oops something went wrong!!");
      }
      });
  }

  reload(){
    location.reload();
  }

}
