import { ThisReceiver } from '@angular/compiler';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';
import { Component, OnInit } from '@angular/core';
  import { FormControl, FormGroup, Validators } from '@angular/forms';
  import { ActivatedRoute, Router } from '@angular/router';
  import { ToastrService } from 'ngx-toastr';
  import { AdminService } from 'src/app/Service/admin.service';
  import { environment } from 'src/environments/environment';
  import { Location } from '@angular/common'
  import { DatePipe } from '@angular/common'
  import * as XLSX from 'xlsx';
import { MatNoDataRow, MatTableDataSource } from '@angular/material/table';
import { saveAs } from 'file-saver';

// import { log } from 'console';

@Component({
  selector: 'app-importschool',
  templateUrl: './importschool.component.html',
  styleUrls: ['./importschool.component.css']
})
  
export class ImportSchoolComponent implements OnInit {
  minCurrent: any;
  actualDate: any;
  activeDate: any;
  public file:any;

  path = environment.bucket;
  postData: any;

  user = true;
  image: any;
  read = true;

  load =false;

  filtercategory: any;
  filterage: any;
  filterlang: any;
  filtertype: any;

  loading = true;
  imgURL: any = "assets/Images/Event.png";
  public imagePath: any;
  public message: any;
  public img: any;
  imageSrc: string;
  book_url: any;
  fileconvert: any;
  book: any;
  updatedMinCurrentForActual: any;
  updatedMaxCurrentForActual: any;
  updatedMinCurrentForActive: any;
  updatedMaxCurrentForActive: any;
  excelData: unknown[];
  file_name:any;
  show=false;
  filename:any;
  displayedColumns: string[] = ['name','low_grade',	'high_grade',	'phone'	, 'zip'	, 'address',	'city',	'state'	, 'admin_email', 'principal_name', 'districts', 'school_code'];
  dataSource: any;
  convertedArray: any = [];


  constructor(private datePipe : DatePipe,private route: ActivatedRoute, private router: Router, private adminService: AdminService, private toastr: ToastrService, private location: Location) { }

  userdetails = new FormGroup({
  file: new FormControl('', Validators.required),

  });


  ngOnInit(): void {

    var date1 = new Date()
      

    const date = new Date();
    this.updatedMinCurrentForActual = this.datePipe.transform(date, 'YYYY-MM-ddThh:mm');
    this.updatedMinCurrentForActive = this.datePipe.transform(date, 'YYYY-MM-ddThh:mm');
    this.updatedMaxCurrentForActive=undefined
    


  }
  actualOnChange(event: any) { 
    console.log("actualOnChange");
    this.actualDate = event.target.value
    
    this.updatedMaxCurrentForActive=this.actualDate   
    if (this.activeDate =='') { 
      this.activeDate=undefined

    }
    else if (this.activeDate == undefined) { 
      this.updatedMaxCurrentForActive=this.actualDate   
    }
    else if (this.activeDate > this.actualDate) { 
      this.toastr.error("Actual Date & Time  should be greater then Active Time")
    }

    this.actualDate=event.target.value
console.log(event.target.value)
  }
  activeOnChange(event: any) { 
    console.log(5556, event.target.value)
    console.log("activeOnChange");
    this.activeDate = event.target.value
   
    this.updatedMinCurrentForActual =  this.activeDate
    console.log(this.updatedMinCurrentForActual, this.activeDate);
    if (this.activeDate == '') { 
      this.activeDate=undefined

    }
    else if (this.actualDate == undefined) { 
      this.updatedMinCurrentForActual=this.activeDate
    }
    else if (this.updatedMinCurrentForActual > this.activeDate) { 
      this.toastr.error("Time should be correct")
    }
    

    else if (this.actualDate < this.activeDate) { 
      console.log("3543")
      this.toastr.error("Active Date can't be greater than Actual Date!!")
    }

  }
  onFileChangeBook(event) {
    const reader = new FileReader();
  //  let file_name;
    if (event.target.files && event.target.files.length) {
      
      const [file] = event.target.files;
     this.filename = event.target.files[0];
      console.log("file=>",file);
      reader.readAsDataURL(file);

      reader.onload = () => {

      
        this.file = event.target.files[0];
        console.log("event.target.files[0]",event.target.files[0].name);
        
        
        
        // file_name = this.file.name;
        // console.log("yy",file_name);
        

        this.userdetails.patchValue({
          fileSource: reader.result
        });
       
      };
    

      
    }

  const file = event.target.files[0];
  this.file_name =  event.target.files[0];
    if (file) {
      const fileReader = new FileReader();
      fileReader.onload = (e) => {
        const arrayBuffer: any = fileReader.result;
        const data = new Uint8Array(arrayBuffer);
        const workbook = XLSX.read(data, { type: 'array' });
        const firstSheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[firstSheetName];
        this.excelData = XLSX.utils.sheet_to_json(worksheet, { raw: true });
        // console.log(this.excelData,55)
        this.dataSource=new MatTableDataSource(this.excelData)
        this.dataSource= this.dataSource.filteredData
         console.log(this.dataSource,"----------------")
      };
      fileReader.readAsArrayBuffer(file);
      this.show=true;
    }
    
  }
  
  getHeaders(row: any){
    console.log(row);
    // return Object.keys(row);
  }
  onFileChangeImage(event) {
    const reader = new FileReader();
    console.log("onFileChangeImage");
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);

      reader.onload = () => {

        

        this.book = event.target.files[0];
        this.userdetails.patchValue({
                  fileSource1: reader.result
                });

      };

    }
  }
  
  reset(){

    this.userdetails.reset();
    this.imageSrc = null
    this.show = false
    this.dataSource=null;

}
  
  onSubmit(value) {

    if (!this.userdetails.valid) {
      this.toastr.error("All fields are required")
    }
    else { 
      
        this.adminService.ImportSchool(this.dataSource).subscribe((response)=>{
        console.log("response",response);

        this.convertedArray = response["data"].map((item: any) => {

          return {
          name: item.name,
          low_grade: item.low_grade,
          high_grade: item.high_grade,
          phone: item.phone,
          zip: item.zip,
          address: item.address,
          city: item.city,
          state: item.state,
          admin_email: item.admin_email,
          principal_name: item.principal_name,
          districts: item.districts,
          school_code: item.school_code,
        }
      })
      this.dataSource = new MatTableDataSource(this.convertedArray)
      this.dataSource = this.dataSource.filteredData
      console.log(this.dataSource,"calling api")

      const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.convertedArray);
      const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
  
      const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      saveAs(blob, 'school_file.xlsx');
  
        if(response['code'] == 1){
          this.loading = true;
          this.toastr.success("School Added!");
          this.ngOnInit();
          this.userdetails.reset();  
        }else{
          this.loading = true;
          this.toastr.error("oops something went Wrong!!")
  
        }      
  
     });
    }
  }

  back() {
    this.location.back()
  }

  }
  
  
  
 
  

